import { A } from '@solidjs/router'
import { Component, Show } from 'solid-js'
import { storageError } from '../../appInit'
import { Iconify } from '../../components/mini-components'
import { useAgent } from './AgentState'

export const getSubOrPub = (id) => {
	return useAgent().subscriptions.find(s => s.id === id)
		?? useAgent().publications.find(s => s.id === id)
}

export const DefaultAgentBanner: Component<{}> = () => {
	const agent = useAgent()
	return (
		<Show when={!agent.isAgentStringSetup}>
			<div flex='~ col' w-full gap-4>
				<sl-alert variant='warning' open w-full>
					<div flex='~ items-center gap-2'>
						<Iconify size='8' name='user-circle-gear' />
						<strong overflow-hidden text-ellipsis>
							You are playing around with generic user info,
							<A href='/settings/app' class='ml-1'>
								click here to configure your username
							</A>
						</strong>
					</div>
				</sl-alert>
			</div>
		</Show>
	)
}

export const StorageMissingBanner: Component<{}> = () => {
	const agent = useAgent()
	return (
		<Show when={!agent.hasStorageSetup}>
			<sl-alert variant='warning' open>
				<div flex='~ items-center gap-4'>
					<div class='i-ph:warning-bold' />
					<strong>No storage set up</strong>
				</div>
				<p mb-0>
					You need to <a href='#/settings/storage'>set up storage</a> before you can publish
				</p>
			</sl-alert>
		</Show>
	)
}
export const StorageErrorBanner: Component<{}> = () => {
	return (
		<div flex='~ col' w-full gap-4>
			<sl-alert variant='warning' open w-full>
				<div flex='~ items-center gap-4'>
					<div class='i-ph:warning-bold' />
					<strong>Storage Error</strong>
				</div>
				<p mb-0>
					{storageError()}
				</p>
			</sl-alert>
		</div>
	)
}
