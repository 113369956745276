import { generateJSON } from '@tiptap/core'
import type { ApplogForInsertOptionalAgent, CidString, EntityID } from '@wovin/core/applog'
import { dateNowIso, EntityID_LENGTH, getHashID } from '@wovin/core/applog'
import { action } from '@wovin/core/mobx'
import { queryAndMap } from '@wovin/core/query'
import type { Thread, ThreadOnlyCurrentNoDeleted, ThreadWithoutFilters, WriteableThread } from '@wovin/core/thread'
import { DefaultFalse, DefaultTrue } from '@wovin/utils'
import { Logger } from 'besonders-logger'
import { XMLBuilder } from 'fast-xml-parser'
import TurndownService from 'turndown'
import { baseExtensions, htmlToSerializedTiptap } from '../components/TipTapExtentions'
import { useBlockAt, useCurrentThread, useRawThread, useRelationVM, withDS } from '../ui/reactive'
import { focusBlockAsInput } from '../ui/utils-ui'
import { AgentStateClass } from './agent/AgentState'
import { insertApplogs, insertApplogsInAppDB } from './ApplogDB'
import { compareBlockContent, serializeTiptapToVl } from './block-utils-nowin'
import { BLOCK_DEF, ENTITY_DEF, REL_DEF } from './data-types'
import type { RelationModelDef } from './Relations'
import { AppSettingsVM } from './VMs/AppSettingsVM'
import { BlockVM, useBlk } from './VMs/BlockVM'
import type { RelBuilder } from './VMs/RelationVM'
import { REL, RelationVM, useRel } from './VMs/RelationVM'
import { TiptapContent } from './VMs/TypeMap'

const { WARN, LOG, DEBUG, VERBOSE, ERROR } = Logger.setup(Logger.DEBUG)

export const notDeletedFilter = en => !en.isDeleted
// const debounceWait = 1500

export function persistBlockContent(thread: Thread, blockID: EntityID, newContent: TiptapContent, pv: CidString = null) {
	const currentContent = withDS(thread, () => useBlockAt(blockID, 'content').get())
	// if (currentContent === null) return // not loaded yet
	if (!compareBlockContent(newContent, currentContent)) {
		DEBUG('set content', { currentContent, newContent, blockID })
		insertApplogs(thread, [{
			en: blockID,
			at: BLOCK_DEF.content,
			vl: serializeTiptapToVl(newContent),
			pv,
		}] /* , { focus: true, id: blockID, pos } */) // TODO: which thread?
	} else VERBOSE('set content noop', { newContent, blockVM: this })
}

// export function persistBlockisDeleted(thread: Thread, blockID: EntityID, newisDeleted: boolean) {
// 	const currentisDeleted = untracked(() => withDS(thread, () => useBlockAt(blockID, 'isDeleted').get())) // ? not needed I think
// 	// if (currentisDeleted === null) return // not loaded yet
// 	if (newisDeleted != currentisDeleted) {
// 		DEBUG('set isDeleted', { currentisDeleted, newisDeleted, blockID })
// 		insertApplogs(thread, [{ en: blockID, at: 'block/isDeleted', vl: newisDeleted }])
// 	} else VERBOSE('set isDeleted noop', { newisDeleted, blockVM: this })
// }

export function htmlToTiptap(html: string) {
	return generateJSON(html, baseExtensions) satisfies TiptapContent
}

export function outdentBlk(thread: Thread, relation: RelationModelDef, grandParentID = null) {
	// const appLogDB = getApplogDB()
	const blockID = relation.block
	const previousParent = relation.childOf
	if (!grandParentID) {
		DEBUG('outdenting up to a root node', { relation })
	}

	DEBUG('move block for outdent', { thread, relation, previousParent, grandParentID })
	moveBlock({ thread, relationID: relation.en, blockID, asChildOf: grandParentID, after: previousParent })
}

export function indentBlk(thread: Thread, relation: RelationModelDef) {
	// check if its possible to indent (i am not the first or only kid -  i must have childof and after)
	if (!relation.after || !relation.childOf) {
		return WARN('indent not possible', { relation })
	}
	// TODO deal with it when more than one kid has the same after (actually legit if the kids were created by differnt agents)
	const moveOptions = { thread, relationID: relation.en, blockID: relation.block, asChildOf: relation.after }
	DEBUG('indent via', moveOptions)
	moveBlock(moveOptions)
}
export function getRecursiveKidsOPML(rootBlockID, includeRoot = DefaultTrue) {
	const rawRS = useRawThread()
	const rootVM = BlockVM.get(rootBlockID, rawRS)
	const opmlObj = {
		'?xml': { _version: '1.0' },
		opml: {
			// 'head': {
			// 	'ownerEmail': 'gotjoshua@gmail.com',
			// },
			'body': {},
			'_version': '2.0',
		},
	}
	const addContent = (bVM: BlockVM, objToMutate) => {
		objToMutate.outline = { _text: bVM.content }
		if (bVM.kidVMs.length) {
			const isSingleKid = bVM.kidVMs.length === 1
			for (const eachBlkVM of bVM.kidVMs) {
				objToMutate.outline.outline = isSingleKid
					? { _text: eachBlkVM.content }
					: bVM.kidVMs.map(eachBlkVM => ({ _text: eachBlkVM.content }))
				if (eachBlkVM.kidVMs.length) {
					addContent(eachBlkVM, objToMutate.outline.outline)
				}
			}
		}
		DEBUG({ opmlObj, bVM })
	}
	addContent(rootVM, opmlObj.opml.body)
	const opml = new XMLBuilder({
		ignoreAttributes: false,
		attributeNamePrefix: '_',
		format: true,
	})
	const opmlResult = opml.build(opmlObj)
	DEBUG('opml', { opml, opmlResult, opmlObj })
	return opmlResult
}
export function getRecursiveKidsJSON(rootBlockID, format: 'md' | 'html') {
	const rawRS = useRawThread()
	const rootVM = BlockVM.get(rootBlockID, rawRS)
	const rootUL = []
	const baseObj = { ul: rootUL }

	const addContent = (bVM: BlockVM, objToMutate) => {
		const thisRootLI = [{
			'#text': bVM.content as string | Record<string, any>,
		}]
		objToMutate.push({ li: thisRootLI })
		if (bVM.kidVMs.length) {
			const kidsLIs = []
			const kidsUL = { ul: kidsLIs }
			for (const eachBlkVM of bVM.kidVMs) {
				if (eachBlkVM.kidVMs.length) {
					addContent(eachBlkVM, kidsLIs)
				} else kidsLIs.push({ li: eachBlkVM.content })
			}
			thisRootLI.push(kidsUL)
		}
		VERBOSE({ baseObj, bVM })
	}
	addContent(rootVM, rootUL)
	const opml = new XMLBuilder({
		ignoreAttributes: false,
		attributeNamePrefix: '_',
		format: true,
		// preserveOrder: true,
		oneListGroup: true,
	})
	let htmlResult, mdResult
	if (format) {
		htmlResult = opml.build(baseObj)
		if (format == 'html') {
			return htmlResult
		}
		const turndownService = new TurndownService({ option: 'value' }) // https://github.com/mixmark-io/turndown
		mdResult = turndownService.turndown(htmlResult)
		return mdResult
	}
	DEBUG('copy node content', rootBlockID, { opml, htmlResult, baseObj, mdResult })
	return baseObj
}

interface AddBlockRelationOptions {
	thread: Thread
	asChildOf: EntityID | null
	after?: EntityID | null
	blockID: EntityID | null
	focus?: boolean
	bottom?: boolean
}
type MoveBlockOptions = AddBlockRelationOptions & { relationID: EntityID | null }

export async function moveBlock({ thread, asChildOf, after, blockID, relationID, focus = true, bottom = true }: MoveBlockOptions) {
	const newApplogs = [
		// TODO: use insertBlockIntoRelChain
		...getAddBlockRelationLogs({ thread, asChildOf, after, blockID, focus: true, bottom }),
	]
	if (relationID) {
		// TODO: use removeBlockFromRelChain?
		newApplogs.push(...getDeleteRelationAtoms(relationID))
	}
	// TODO update after situations of leftovers
	insertAndMaybeFocus(thread, newApplogs, { inputFocus: blockID, id: blockID, end: true })
}
export function getAddBlockRelationLogs({ thread, asChildOf, after, blockID, focus = true, bottom = true }: AddBlockRelationOptions) {
	// TODO: use insertBlockIntoRelChain?
	const newRelationID = getHashID([asChildOf, blockID, dateNowIso()], EntityID_LENGTH)
	const blockToMoveVM = BlockVM.get(blockID, thread)
	const newParentBlockVM = asChildOf && BlockVM.get(asChildOf, thread)
	let newApplogs: ApplogForInsertOptionalAgent[]
	if (!after) {
		if (bottom) {
			const currentBottomBlockID = after = (newParentBlockVM.kidRelations.slice(-1)[0] ?? {}).block ?? null // explicit null if there are no kids to be at the bottom of
			VERBOSE('using bottom as after', { currentBottomBlockID, newParentBlockVM, blockToMoveVM })
		} else {
			WARN('a bit odd to explicitly disable bottom and not pass after')
		}
	}
	newApplogs = getAppLogsForNewRelation({ newRelationID, asChildOf, after, blockID })
	VERBOSE('getAddBlockRelationLogs returning', { newApplogs })
	return newApplogs
}
export function getAppLogsForNewRelation({ asChildOf, after, newRelationID, blockID }: {
	newRelationID: EntityID | null
	asChildOf: EntityID | null
	blockID: EntityID | null
	after?: EntityID | null
}) {
	// TODO: use insertBlockIntoRelChain?
	const newApplogs = []
	if (asChildOf) {
		newApplogs.push(
			{ en: newRelationID, at: 'relation/childOf', vl: asChildOf },
			{ en: newRelationID, at: 'relation/block', vl: blockID },
			{ en: newRelationID, at: 'relation/after', vl: after ?? null },
		)
		// TODO get parentRelation
		// TODO if(!parentRelation.isExpanded) {newApplogs.push({ en: parentRelation.id, at: REL_DEF.isExpanded, vl: true })}
	}
	return newApplogs as ApplogForInsertOptionalAgent[]
}
export interface AddBlockOpts {
	thread: ThreadOnlyCurrentNoDeleted
	asChildOf: EntityID | null
	after?: EntityID | null
	content?: string
	inputFocus?: boolean
}
export const addBlock = action(
	function addBlock({
		thread,
		asChildOf,
		after = null,
		content = '', // TODO: TIPTAP_EMPTY?
		inputFocus = false,
	}: AddBlockOpts) {
		DEBUG('Adding block', { asChildOf, after, content, focus: inputFocus })

		const blockBuilder = BlockVM
			.buildNew({ content: content as any as TiptapContent }) // HACK: content type?!
			.ensureEn() // TODO: add salt (e.g. asChildOf)?
		// const relBuilder = RelationVM.buildNew({
		// 	block: blockBuilder.en,
		// 	childOf: asChildOf,
		// 	after: after || null,
		// })

		// 	if (focus)
		// relBuilder.update({isExpanded:true}) // ? default isExpanded is true anyways, sure we want it explicit
		insertApplogs(thread, [
			...blockBuilder.build(),
			// ...relBuilder.build(),
		])
		// TODO make into transaction style and offer option to return applogs without commit
		if (asChildOf) {
			var newRelID = insertBlockInRelChain(thread, blockBuilder.en, asChildOf, after ?? null)
		}

		maybeFocusAfterInsert({ inputFocus, id: blockBuilder.en, end: false })
		DEBUG('Block created:', blockBuilder.en, { newRelID })
		return blockBuilder.en
	},
)
function insertAndMaybeFocus(
	thread: WriteableThread,
	newApplogs: ApplogForInsertOptionalAgent[],
	{ id = '', inputFocus = DefaultFalse, start = DefaultFalse, end = DefaultFalse, pos = 0 } = {},
) {
	const _insertResult = insertApplogs(thread, newApplogs)
	maybeFocusAfterInsert({ inputFocus, id, end, pos, start })
}
function maybeFocusAfterInsert(
	{ id = '', inputFocus = DefaultFalse, start = DefaultFalse, end = DefaultFalse, pos = 0 } = {},
) {
	if (!id) return
	if (inputFocus) {
		setTimeout(() => {
			focusBlockAsInput({ id, end, pos, start })
		})
	}
}

export function getDeleteRelationAtoms(en: EntityID) {
	return [{ at: 'isDeleted', en, vl: true }]
}
// export function deleteRelation(en: EntityID) {
// 	const atoms = getDeleteRelationAtoms(en)
// 	VERBOSE('deleting relation', en, { atoms })
// 	insertApplogsIDB(atoms)
// }
export function removeBlockRelAndMaybeDelete(thread: Thread, blockID: EntityID, relationID: EntityID | null) {
	const blockVM = useBlk(blockID, thread)
	const appLogsToInsert = relationID ? getDeleteRelationAtoms(relationID) : []
	if (!relationID || blockVM.parentRelations.length <= 1) {
		appLogsToInsert.push({ en: blockID, at: 'isDeleted', vl: true })
	}
	DEBUG(`[removeBlockRelAndMaybeDelete]`, blockID, { relationID, parents: blockVM.parentRelations })
	if (!appLogsToInsert.length) {
		WARN('somethings off, removeBlockRelAndMaybeDelete did nothing')
	} else insertApplogsInAppDB(appLogsToInsert)
}

export function deleteAndReplaceBlock(relationID: EntityID, newBlockID: string) {
	const relation = useRelationVM(relationID)
	// TODO what if its not the only reference of the block??
	// i think only after and isExpanded ought to be changable...
	// otherwise (i thought we already agreed that) deleting the relation and making a new one is better
	insertApplogsInAppDB([
		// delete block
		{ en: relation.block, at: ENTITY_DEF.isDeleted, vl: true },
		// update relation to point at pasted block
		{ en: relation.en, at: REL_DEF.block, vl: newBlockID },
	])
}

export function removeBlockFromRelChain(thread: ThreadOnlyCurrentNoDeleted, blockID: EntityID, relationToParentID: EntityID) {
	DEBUG(`[removeBlockFromRelChain]`, { blockID, relationToParentID })
	const relationToParent = useRel(relationToParentID, thread)
	const wasAfterRelID = relationToParent.after
	const relationsAfterMe = queryAndMap(thread, [
		{ en: '?relID', at: REL.childOf, vl: relationToParent.childOf }, // relations of siblings
		{ en: '?relID', at: REL_DEF.after, vl: blockID }, // that are 'after' this block
	], 'relID')
	DEBUG(`[removeBlockFromRelChain] data:`, { wasAfterRelID, relationsAfterMe })
	if (relationsAfterMe.length) {
		insertApplogsInAppDB([
			// update relations referring to me
			...relationsAfterMe.map(relID => (
				{ en: relID, at: REL_DEF.after, vl: wasAfterRelID }
			)),
		])
	}
}

export function insertBlockInRelChain(
	thread: ThreadOnlyCurrentNoDeleted,
	blockID: EntityID,
	parentID: EntityID,
	after: EntityID | null,
	relationToParentID?: EntityID,
) {
	DEBUG(`[insertBlockInRelChain]`, { blockID, parentID, after, relationToParentID })

	let relBuilder: InstanceType<typeof RelBuilder>
	if (relationToParentID) {
		const relToParentVM = useRel(relationToParentID, thread)
		relBuilder = relToParentVM.buildUpdate()
		if (relToParentVM.childOf !== parentID) {
			relBuilder.update({ childOf: parentID })
		}
	} else {
		relBuilder = RelationVM.buildNew({ block: blockID, childOf: parentID })
	}
	relBuilder.update({ after })
	relationToParentID = relBuilder.commit().en

	// update relations to refer to me
	const relationsAfterTarget = queryAndMap(thread, [
		{ en: '?rel', at: REL_DEF.childOf, vl: parentID }, // relations of siblings
		{ en: '?rel', at: REL_DEF.after, vl: after }, // that are 'after' who we want to be after
	], 'rel')
		.filter(rel => rel !== relationToParentID) // except my relation
	if (relationsAfterTarget.length) {
		DEBUG(
			`[insertBlockInRelChain] relationsAfterTarget:`,
			relationToParentID,
			blockID,
			relationsAfterTarget, /* , { relationsAfterTargetQuery, relationsOfParent } */
		)
		insertApplogsInAppDB([
			...relationsAfterTarget.map(rel => (
				{ en: rel, at: REL_DEF.after, vl: blockID ?? null }
			)),
		])
	}
	return relBuilder.en
}

export function getEditorForBlockID(blockID: string) {
	return (window.document.querySelector(`#block-${blockID} .tiptap`) as HTMLDivElement & { editor: any }).editor
}

export function createHomeBlockForAgent(appSettings: AppSettingsVM, appThread: ThreadWithoutFilters, agent: AgentStateClass) {
	DEBUG(`Creating home block`, { appSettings })
	const home = addBlock({
		thread: withDS(appThread, useCurrentThread),
		asChildOf: null,
		content: htmlToSerializedTiptap(`Home Block — <em>${agent.agentString}</em>`),
	})
	appSettings.update({ homeBlock: home })
	LOG(`Created home block`, { home, appSettings })
}
