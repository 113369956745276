import { IPublication, ISubscription } from '@wovin/core'
import { comparer, flow, observable, runInAction } from '@wovin/core/mobx'
import { Logger } from 'besonders-logger'

import { notifyToast } from '../../ui/utils-ui'
import { stateDB } from '../local-state'
import { type AgentStateClass, useAgent } from './AgentState'
const { WARN, LOG, DEBUG, VERBOSE, ERROR } = Logger.setup(Logger.INFO) // eslint-disable-line no-unused-vars

export class AgentPubSub {
	mixed = this as unknown as AgentStateClass
	readonly publications = observable.array([] as IPublication[], { deep: true, equals: comparer.structural })
	readonly subscriptions = observable.array([] as ISubscription[], { deep: true, equals: comparer.structural })

	get publicationsMap() {
		return new Map(this.publications.map(pub => [pub.id, pub]))
	}
	get subscriptionsMap() {
		return new Map(this.subscriptions.map(sub => [sub.id, sub]))
	}
	get mostRecentPublication() {
		if (!this.publications.length) return null
		return this.publications[this.publications.length - 1]
	}
	getDirectoryHandle = flow(function*(this: AgentStateClass) {
		return (yield stateDB.directoryHandles.get(this.ag))?.directoryHandle
	})
	setDirectoryHandle = flow(function*(this: AgentStateClass, directoryHandle: FileSystemDirectoryHandle) {
		LOG(`Set Handle:`, directoryHandle)
		// (i) dexie does some type checking, so we do it first & await
		yield stateDB.directoryHandles.put({ ag: this.ag, directoryHandle })
	})
	addPub = flow(function*(this: AgentStateClass, pub: IPublication) {
		LOG(`Adding publication:`, pub)
		// (i) dexie does some type checking, so we do it first & await
		yield stateDB.publications.add(pub)
		this.reloadPubSub()
	})
	addSub = flow(function*(this: AgentStateClass, sub: ISubscription) {
		LOG(`Adding subscription:`, sub)
		yield stateDB.subscriptions.add(sub)
		this.reloadPubSub()
	})
	updateSub = flow(function*(this: AgentStateClass, id: string, changes: Partial<Omit<ISubscription, 'id'>>) {
		const sub = this.subscriptions.find(p => p.id === id)
		if (!sub) throw ERROR(`[updateSub] id not found:`, id, changes)
		LOG(`Updating subscription:`, id, changes, sub)
		yield stateDB.subscriptions.update(id, changes)
		Object.assign(sub, changes)
		// this.reloadPubSub()
	})
	updatePub = flow(function*(this: AgentStateClass, id: string, changes: Partial<Omit<IPublication, 'id'>>) {
		const pub = this.publications.find(p => p.id === id)
		if (!pub) throw ERROR(`[updatePub] id not found:`, id, changes)
		const { publishedBy } = pub
		const { ag } = useAgent()
		if (ag !== publishedBy) {
			WARN(`[updatePub] last publish was a different agent`, { publishedBy, ag })
			notifyToast(
				`This publication was published last by a different agent (${publishedBy} --> ${ag}) if you did not change your agent string, something may be up with your DID situation`,
				'warning',
			)
			changes.publishedBy = ag
		}
		LOG(`Updating publication:`, id, changes, pub)
		void stateDB.publications.update(id, changes)
		Object.assign(pub, changes)
		// this.reloadPubSub()
	})
	deletePub = flow(function*(this: AgentStateClass, pubID: string) {
		const pub = this.publications.find(p => p.id === pubID)
		LOG(`Deleting publication:`, pubID, pub)
		if (!pub) throw ERROR(`Trying to delete non-existing publication:`, pubID)
		// yield stateDB.publications.update(pubID, { isDeleted: true })
		yield stateDB.publications.delete(pubID)
		this.reloadPubSub()
	})
	deleteSub = flow(function*(this: AgentStateClass, subID: string) {
		const sub = this.subscriptions.find(p => p.id === subID)
		LOG(`Deleting subscription:`, subID, sub)
		if (!sub) throw ERROR(`Trying to delete non-existing subscription:`, subID)
		// yield stateDB.subscriptions.update(subID, { isDeleted: true })
		yield stateDB.subscriptions.delete(subID)
		this.reloadPubSub()
	})

	reloadPubSub = async () => {
		const publications = (await stateDB.publications.orderBy(/* 'lastPush',  */ 'createdAt').toArray()).filter(p => !p.isDeleted)
		const subscriptions = (await stateDB.subscriptions.orderBy(/* 'lastPull',  */ 'createdAt').toArray()).filter(p => !p.isDeleted)
		runInAction(() => {
			this.publications.replace(publications)
			this.subscriptions.replace(subscriptions)
		})
		DEBUG(`Loaded from IDB:`, { pubs: this.publications, subs: this.subscriptions, stateDB })
	}
}
