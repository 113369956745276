import { useLocation, useNavigate, useSearchParams } from '@solidjs/router'
import type { Editor } from '@tiptap/core'
import Image from '@tiptap/extension-image'
import { Slice } from '@tiptap/pm/model'
import { EditorView } from '@tiptap/pm/view'
import type { EntityID } from '@wovin/core/applog'
import { untracked } from '@wovin/core/mobx'
import { Logger } from 'besonders-logger'
import classNames from 'classnames'
import { pick } from 'lodash-es'
import { stringify } from 'safe-stable-stringify'
import type { Accessor, Component, JSX, Setter } from 'solid-js'
import { createEffect, createMemo, createSignal, on, onCleanup, onMount, Show, splitProps, untrack } from 'solid-js'
import { createTiptapEditor, useEditorJSON } from 'solid-tiptap'
import { useAgent } from '../data/agent/AgentState'
import {
	copyToClipboard,
	createTreeItemKeydownHandler,
	createTreeItemPasteHandler,
	getPlainTextFromClipboard,
	getValidURLFromClipboard,
	useBlockContext,
} from '../data/block-ui-helpers'
import { compareBlockContent, plaintextStringToTiptap, tiptapToPlaintext } from '../data/block-utils-nowin'
import { REL_DEF } from '../data/data-types'
import { useBlockKeyhandlers } from '../data/keybindings'
import { doesContentMatchSearch, useSearch } from '../data/search'
import { BlockVM, useBlk } from '../data/VMs/BlockVM'
import { useBlockVM, useCurrentThread, useReadOnlyState, useRelationVM, useThreadWithFilters } from '../ui/reactive'
import { devMode, EventDebugger, focusViewOnBlock, onClickOrLongPress } from '../ui/utils-ui'
import { BlockSettingsRow } from './BlockSettings'
import { FakeBlock } from './BlockTree'
import { BulletMenu } from './BulletMenu'
import { DynamicColored, Iconify, IconifyNames, useIdHover } from './mini-components'
import {
	baseExtensions,
	CreateTokenHidingExtension,
	htmlToTiptap,
	SelectionDetectLinkHandler as CustomSelectionHandler,
	TagHighlightExtension,
	WordInfoExtension,
} from './TipTapExtentions'
import { TiptapMenuWrapper } from './TiptapMenuWrapper'
const { WARN, LOG, DEBUG, VERBOSE, ERROR } = Logger.setup(Logger.INFO)

export const BlockItem: Component<{
	blockID: EntityID | null
	fake?: FakeBlock
	relationID: EntityID | null
	hasKids: boolean
	readOnly?: boolean // ? kind of redundant with thread context readonly
	isExpanded: Accessor<boolean>
	hiddenTags: Accessor<readonly string[]>
	setExpanded: Setter<boolean> | ((newVal: boolean) => any)
}> = (props) => {
	const { blockID, fake } = props // assumed static
	const debugName = untracked(() => blockID ?? JSON.stringify(fake))
	if (!blockID && !fake) {
		throw ERROR(`<Block> with falsy blockID`, blockID)
	}
	VERBOSE(`[BlockItem#${debugName}] created`)

	const location = useLocation()
	const navigate = useNavigate()
	const [searchParams, _setSearchParams] = useSearchParams()
	const previewFromUrl = createMemo(() => {
		DEBUG(`searchParams:`, { ...searchParams })
		return searchParams.preview
	})

	const { isExpanded, setExpanded } = props
	const thread = useCurrentThread()

	const blockContext = useBlockContext()
	const parentContext = blockContext?.parentContext
	// const relation = props.relationID && useRelation(props.relationID)
	const relVM = props.relationID && useRelationVM(props.relationID)
	const blkVM = fake ? undefined : useBlk(blockID)
	const blockOrFake = fake ?? blkVM
	const ag = useAgent().ag
	const isDeleted = createMemo(() => (blockOrFake as BlockVM).isDeleted)
	const readOnly = createMemo(() => props.readOnly || !!props.fake || thread.readOnly || isDeleted())

	const [search] = useSearch()
	const shouldHide = createMemo(() => !fake && search() && !doesContentMatchSearch(blkVM.contentPlaintext ?? '', search())) // HACK
	const [blockSettings, setBlockSettings] = createSignal(false)
	function toggleBlockSettings() {
		setBlockSettings(!blockSettings())
	}
	let blockRef: HTMLDivElement
	let editorRef: HTMLDivElement
	useBlockKeyhandlers(() => ({ blockRef, editorRef }))
	// TODO needs reactivity support:
	const pasteHandler = fake ? EventDebugger : createTreeItemPasteHandler(relVM, blkVM)
	// ? those too?
	const keyDownHandler = createTreeItemKeydownHandler(blockID, props.relationID, isExpanded, setExpanded, ag)
	const bulletIconName = blockOrFake?.isReply // HACK: BlockVM doesn't know about view context (but for fake block it's good to be able to set it)
		? (blockOrFake.kidRelations?.length
			? IconifyNames['arrow-bend-double-up-left-bold']
			: IconifyNames['arrow-bend-down-right-bold'])
		: (props.hasKids
			? 'dot-duotone'
			: 'dot-bold')
	const onExpandClick = (event: MouseEvent, long: boolean) => {
		event.preventDefault()
		VERBOSE({ event, onClickOrLongPress })
		if (event.button === 1 || long) {
			focusViewOnBlock({ id: blockID }, location, navigate)
		} else {
			// TODO: if (event.button === 0) {
			setExpanded(!isExpanded())
			// ?  else ... (fake)-expand or contract all children
		}
	}
	return (
		<div
			class='block-content'
			data-hidden-tags={stringify(props.hiddenTags?.() ?? '')}
			data-info={fake ? `smartResult:${parentContext?.smartList?.query.source}` : `block:${blockID}`}
		>
			<div ref={blockRef} flex='~ items-start' data-block-id={blockID} class={shouldHide() ? 'opacity-60' : ''}>
				{/* <Show when={!shouldHide()}> */}
				{/* <Show when={blockOrFake.type === 'smartlist' || props.hasKids}> */}
				<div
					class='order-9 sm:order-0 cursor-pointer color-gray-400'
					flex='shrink-0'
					// onMouseDown={onExpandClick} // mousedown to not loose focus - https://stackoverflow.com/a/43627784
					use:onClickOrLongPress={onExpandClick}
				>
					<Iconify
						class={classNames([
							'color-gray-400 duration-300 transition-property-[transform]',
							isExpanded() ? ' rotate-90' : ' rotate-0',
						])}
						size={'6 6'}
						scale={60}
						name={props.hasKids ? 'caret-right-bold' : null}
					/>
				</div>
				{/* </Show> */}
				<BulletMenu
					class='mr-1'
					blockID={blockID}
					icon={bulletIconName}
					showBlockSettings={toggleBlockSettings}
				/>
				<Show when={blockOrFake.type === 'smartlist'}>
					<sl-tag
						size='small'
						variant='primary'
						onClick={() => setBlockSettings(!blockSettings())}
					>
						<Iconify name='list-magnifying-glass' scale={120} />
					</sl-tag>
					<span ml-1 opacity-40 text-xs>{parentContext?.smartList?.query.source}</span>
				</Show>
				<EditableContent
					ref={editorRef}
					blockID={blockID}
					fake={fake ?? (isDeleted() && {
						content: htmlToTiptap('<em>deleted</em>'),
					})}
					relationID={props.relationID}
					onPaste={pasteHandler}
					onKeyDown={keyDownHandler}
					readOnly={readOnly()}
					flex='grow-1 shrink-1'
					class={classNames(
						readOnly && 'cursor-pointer',
						isDeleted() && 'opacity-80 text-red',
					)}
					onClick={readOnly() ? e => setExpanded(!isExpanded()) : undefined}
				/>
				{
					/* <Show when={blockOrFake.type === 'smartlist'}>
					<SmartListFilters kidCount={parentContext?.smartList()?.blocks.length} />
				</Show> */
				}
				<Show when={devMode()}>
					<span
						flex='inline col items-stretch'
						ml-1
						font-size-2
						font-mono
						text-right
					>
						<Show when={blockID}>
							<div {...(useIdHover('block', blockID))} flex='~ items-stretch' transition='[opacity] duration-300'>
								B&thinsp;
								<DynamicColored
									text={blockID}
									class='cursor-pointer'
									onClick={() => copyToClipboard(blockID)}
									title='click to copy'
								/>
							</div>
						</Show>
						<Show when={props.relationID}>
							<div {...(useIdHover('block', props.relationID))} transition='[opacity] duration-300' flex='~ items-stretch'>
								R&thinsp;{' '}
								<DynamicColored text={props.relationID} title={JSON.stringify(pick(relVM, Object.values(REL_DEF._attrs)), undefined, 2)} />
							</div>
							<div {...(useIdHover('block', relVM?.after))} transition='[opacity] duration-300' flex='~ items-stretch'>
								↑&thinsp; <DynamicColored text={relVM?.after ?? 'null'} />
							</div>
						</Show>
					</span>
				</Show>
				{/* </Show> */}
			</div>

			<Show when={blockSettings()}>
				{/* HACK: prop drilling */}
				<BlockSettingsRow blockID={blockID} thread={thread} blockOrFake={blockOrFake} />
			</Show>
		</div>
	)
}

// HACK: I'm not sure how to get from dom node to Editor in another way
export const editorMap = new Map<HTMLDivElement, Editor>()

// const CustomShortcuts = Extension.create({
// 	name: 'CustomShortcuts',
// 	addKeyboardShortcuts() {
// 		return {
// 			// HACK: move default Enter behaviour to Shift-Enter (as our Enter creates a new block)
// 			'Shift-Enter': ({ editor }) =>
// 				// from: https://github.com/ueberdosis/tiptap/blob/39cf6979c49e953118bbbe4b894a1dc296128932/packages/core/src/extensions/keymap.ts#L49C25-L54C7
// 				editor.commands.first(({ commands }) => [
// 					() => commands.newlineInCode(),
// 					() => commands.createParagraphNear(),
// 					() => commands.liftEmptyBlock(),
// 					() => commands.splitBlock(),
// 				]),
// 		}
// 	},
// })

export const EditableContent: Component<
	{
		readOnly?: boolean
		blockID: EntityID | null
		fake?: FakeBlock
		relationID: EntityID | null
		// blockVM: BlockVM
		// block: typeof BLOCK
		// vintageContent: Accessor<string>
		onKeyDown: (evt: React.KeyboardEvent<HTMLDivElement>) => Promise<void>
		onPaste: (view: EditorView, event: ClipboardEvent, slice: Slice) => boolean | void
	} & JSX.HTMLAttributes<HTMLSpanElement>
> = (fullProps) => {
	const [props, otherProps] = splitProps(fullProps, ['readOnly', 'blockID', 'relationID', 'fake', 'onKeyDown', 'onPaste']) // (i) this function is still not properly reactive to those, though
	const readOnly = createMemo(() => props.readOnly || useReadOnlyState()())
	VERBOSE(`<EditableContent#${props.blockID}> created`, { props, otherProps, ro: readOnly(), ds: useCurrentThread() })
	const blockVM = props.fake ?? useBlockVM(props.blockID)
	const [isEditing, setEditing] = createSignal(null)
	const [selectionLink, setSelectionLink] = createSignal<{ href: string } | null>(null)

	let ref!: HTMLDivElement
	// let menuRef!: HTMLDivElement
	Image.configure({
		allowBase64: true,
	})
	const initialContent = untracked(() => blockVM.content)

	VERBOSE(`[Content#${props.blockID}] initial`, initialContent)

	const fakeContent = props.fake?.content && (typeof props.fake.content === 'string'
		? plaintextStringToTiptap(props.fake.content)
		: props.fake.content)
	// TODO decorate or somehow hide the outer (and maybe recursive) smartlist tokens
	// ... somehow based on parent block context isTokenHidden prop of smartlist
	const blockContext = useBlockContext()
	const parentContext = blockContext?.parentContext

	const extensionsMemo = createMemo(() => {
		const extensions = [
			...baseExtensions,
			Image,
			// CustomShortcuts,
			TagHighlightExtension,
			WordInfoExtension,
			CustomSelectionHandler(selectionLink, setSelectionLink),
			// BubbleMenu.configure({
			// 	element: menuRef!,
			// }),
		]

		const hiddenTags = parentContext?.hiddenTags()
		if (hiddenTags) {
			VERBOSE('CreateTokenHidingExtension', { hiddenTags })
			extensions.push(CreateTokenHidingExtension(hiddenTags))
		}
		return extensions
	})

	const editor = createTiptapEditor(() => {
		const editorProps = {
			element: ref!,
			editable: !readOnly(),
			editorProps: {
				attributes: {
					//   class: 'prose prose-sm sm:prose lg:prose-lg xl:prose-2xl mx-auto focus:outline-none',
					class: 'focus:outline-none -my-3',
				},
				// handlePaste: CustomStackedPasteHandler([props.onPaste /* () => LOG('handler1'), () => LOG('handler2') */]),
				handlePaste: props.onPaste,
			},
			extensions: extensionsMemo(),
			content: fakeContent ?? initialContent,
		}
		DEBUG(`<EditableContent.tiptap> props`, editorProps)
		return editorProps
	})
	onMount(() => {
		editorMap.set(ref!, editor())
	})
	onCleanup(() => {
		editorMap.delete(ref!)
	})

	const contentJson = useEditorJSON(editor)
	if (!readOnly()) {
		createEffect(on(() => contentJson(), newContent => {
			DEBUG(`[EditableContent#${props.blockID}].htmlUpdate]`, newContent, { blockVM })

			if (!compareBlockContent(newContent, blockVM.content)) {
				DEBUG.force('[EditableContent#${props.blockID}].htmlUpdate] changed', { old: blockVM.content, new: newContent /* , parsed  */ })

				blockVM.content = newContent // this is a json object

				setEditing(setTimeout(() => setEditing(null), 1000)) // HACK: unify with BlockVM.isEditing
				// persistBlockContent(block.en, spanText)
			}
		}, { defer: true })) // (i) still called on first load (I think)
		createEffect(on(() => blockVM.content, newContent => {
			DEBUG(`[EditableContent#${props.blockID}] newContent:`, newContent, { ref, isEditing: blockVM.isEditing })
			if (!isEditing()) {
				if (!compareBlockContent(contentJson(), newContent)) {
					editor().commands.setContent(newContent)
				}
			}
		}, { defer: true })) // don't call on first load
	}

	const handleLinkButton = async (_evt: PointerEvent) => {
		if (editor().isActive('link')) {
			DEBUG('removing', editor().getAttributes('link').href)
			// (i) tries to extend to
			editor().chain().focus().extendMarkRange('link').unsetLink().run()
			setSelectionLink(null)
		} else {
			const maybeValidURL = await getValidURLFromClipboard()
			if (maybeValidURL) {
				VERBOSE('setting link in tiptap', maybeValidURL)
				editor().chain().focus().toggleLink({ href: maybeValidURL }).run()
				setSelectionLink({ href: maybeValidURL })
				// ? i guess focus is needed cuz the mini menu steals it on click ?
			} else {
				WARN('Link Button clicked but no link found', await getPlainTextFromClipboard())
			}
		}
	}
	if (props.fake) VERBOSE('fake:', props.fake)
	return (
		<>
			<div
				{...otherProps}
				onKeyDown={props.onKeyDown}
				ref={ref}
				id={props.fake
					? `fake:${props.fake.smartQuery?.text ?? tiptapToPlaintext(props.fake.content)
						/* ?? props.fake.content?.content?.[0]?.content?.[0]?.text */
					}`
					: `block-${blockVM.en}`}
				flex='grow-1'
				style={{ 'width': '0' }} // HACK to get break-words to work
			/>
			<TiptapMenuWrapper
				editor={editor()}
				tippyOptions={{ duration: 300 }}
			>
				{/* shouldShow={({ editor, state, view, from, to }) => { }} */}
				{/* FIXME: tooltips not shown */}
				<sl-button-group
					label='Formatting'
					flex
					drop-shadow-lg
					style='background-color: hsl(240 5% 27.6%); border: 1px solid hsl(240 5% 27.6%); border-radius: 0.25rem;'
				>
					<sl-tooltip content='Bold'>
						<sl-button size='small' onClick={() => editor().chain().focus().toggleBold().run()}>
							<Iconify slot='prefix' scale={140} name='text-b-bold' />
						</sl-button>
					</sl-tooltip>
					<sl-tooltip content='Italic'>
						<sl-button size='small' onClick={() => editor().chain().focus().toggleItalic().run()}>
							<Iconify slot='prefix' scale={140} name='text-italic-bold' />
						</sl-button>
					</sl-tooltip>
					<sl-tooltip content='Underline'>
						<sl-button size='small' onClick={() => editor().chain().focus().toggleUnderline().run()}>
							<Iconify slot='prefix' scale={140} name='text-underline-bold' />
						</sl-button>
					</sl-tooltip>
					<sl-tooltip content='Strikethrough'>
						<sl-button size='small' onClick={() => editor().chain().focus().toggleStrike().run()}>
							<Iconify slot='prefix' scale={140} name='text-strikethrough-bold' />
						</sl-button>
					</sl-tooltip>
					<sl-tooltip content='Regular style'>
						<sl-button size='small' onClick={() => editor().chain().focus().unsetAllMarks().run()}>
							<Iconify slot='prefix' scale={140} name='eraser-fill' />
						</sl-button>
					</sl-tooltip>
					<div h-full style='background-color: hsl(240 5% 27.6%); width: 2px'>
						{/* divider */}
					</div>

					<Show when={!selectionLink()}>
						<sl-tooltip content='Link'>
							<sl-button size='small' onClick={handleLinkButton}>
								<Iconify slot='prefix' scale={140} name='link-bold' />
							</sl-button>
						</sl-tooltip>
					</Show>
					<Show when={selectionLink()}>
						<sl-tooltip content={'Remove link: ' + selectionLink().href}>
							<sl-button size='small' onClick={handleLinkButton}>
								<Iconify slot='prefix' scale={140} name='link-break-bold' />
							</sl-button>
						</sl-tooltip>
						<a href={selectionLink().href} target='_blank'>
							{/* <sl-tooltip content={'Open: ' + selectionLink().href}> */}
							<sl-button size='small'>
								{/* onClick={() => window.open(selectionLink().href, '_blank')} */}
								<Iconify slot='prefix' scale={140} name='arrow-square-out' />
							</sl-button>
						</a>
						{/* </sl-tooltip> */}
					</Show>
				</sl-button-group>
			</TiptapMenuWrapper>
		</>
	)
	// return (
	// 	<span
	// 		{...otherProps}
	// 		focus-outline-none
	// 		role='textbox'
	// 		onBlur={onBlur}
	// 		onKeyUp={onType}
	// 		onKeyDown={local.onKeyDown}
	// 		onPaste={local.onPaste}
	// 		contenteditable
	// 		p-1
	// 		cursor-text
	// 		min-w-16
	// 		ref={contentElem}
	// 		id={'block-' + blockVM.enID}
	// 		break-all // TODO: should be only break-words, but doesn't work
	// 		hyphens-auto
	// 	>
	// 		{/* w-full w-min-content */}
	// 		{untracked(() => blockVM.content)}
	// 	</span>
	// )
}
