import type { Applog } from '@wovin/core'
import { encodeApplog, encodeApplogAndGetCid, WriteableThread } from '@wovin/core'
import { makeObservable, observable } from '@wovin/core/mobx'
import { Logger } from 'besonders-logger'
import Dexie from 'dexie'
import { defer } from 'lodash-es'

export class Note3_AppLogDB extends Dexie {
	applogs!: Dexie.Table<Applog, string>
	// agents!: Dexie.Table<IEmailAddress, number>
	// config!: Dexie.Table<IPhoneNumber, number>

	constructor() {
		super('Note3_AppLogDB')

		//
		// Define tables and indexes
		// (Here's where the implicit table props are dynamically created)
		//
		this.version(2).stores({
			applogs: 'cid, en, at, vl, ag, ts',
		})
	}
}

export const appLogIDB = new Note3_AppLogDB()

const { WARN, LOG, DEBUG, VERBOSE, ERROR } = Logger.setup(Logger.INFO, { prefix: '[db]' })

export class ThreadIDB extends WriteableThread {
	constructor(name: string, applogs: Applog[] = [], filters: readonly string[] = []) {
		super(name, applogs, filters)

		makeObservable(this, {
			// @ts-expect-error bc it's private
			_applogs: observable.shallow,
		})
	}

	protected async persist(logs: Applog[]) {
		// when we get the cid, do we want to drop the pv and cid props
		// i mean what content do we want to address with this cid?
		defer(async () => {
			DEBUG(`[IDB.persist] persisting`, logs)
			if (localStorage.getItem('note3.dev.noPersist')) {
				// @ts-expect-error
				if (!this.noPersistWarned) {
					WARN(`Persistence is DISABLED via localStorage:'note3.dev.noPersist'`, this)
					// @ts-expect-error
					this.noPersistWarned = true
				}
				return DEBUG(`[IDB.persist] noPersist`)
			}
			appLogIDB.applogs?.bulkPut(
				await Promise.all(logs.map(async (eachLog) => {
					const cid = (encodeApplogAndGetCid(eachLog)).toString() // TODO: skip if existing - if we're sure this ain't happening:
					if (eachLog.cid && eachLog.cid !== cid) {
						ERROR(`In-mem CID is different than when persisting`, { eachLog, cid, encoded: encodeApplog(eachLog) })
					}
					return ({ ...eachLog, cid })
				})),
			)
		})
	}

	get readOnly() {
		return false
	}
}

export async function deleteAllApplogs({ sure }: any) {
	if (sure !== 'I am') throw ERROR(`[deleteAllApplogs] but not sure?!`)
	else {
		await appLogIDB.applogs.clear()
	}
}
