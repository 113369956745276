import { SymmAlg } from '@oddjs/odd/components/crypto/implementation'
import { dateNowIso, IpnsString } from '@wovin/core/applog'
import { flow } from '@wovin/core/mobx'
import { IPublication, ISubscription } from '@wovin/core/pubsub'
import { arrayBufferToBase64 } from '@wovin/utils'
import { Logger } from 'besonders-logger'
import { keys } from 'libp2p-crypto'
import * as W3Name from 'w3name'
import { WritableName } from 'w3name'
import { createAESkeyForPublication, derivePublicationKeySeed, deriveSharedEncryptionKey } from '../data/agent/AgentCrypto'
import { useAgent } from '../data/agent/AgentState'
import { getSubOrPub } from '../data/agent/utils-agent'

const { WARN, LOG, DEBUG, VERBOSE, ERROR } = Logger.setup(Logger.DEBUG, { prefix: '[sub-pub]' }) // eslint-disable-line no-unused-vars

export const upsertSubscription = flow(function* upsertSubscription(
	ipns: IpnsString,
	subFields:
		& Partial<Pick<ISubscription, 'name' | 'lastPull' | 'encryptedFor' | 'lastCID' | 'autopull' | 'lastPullAttempt' | 'lastApplogCID'>>
		& { encryptedBy?: string; derivationJWKstring?: string } = {},
) {
	LOG('Upserting subscription:', { ipns, subFields })
	const agent = useAgent()

	const { ag } = agent

	const subscriptionObj: ISubscription = {
		id: ipns,
		createdAt: dateNowIso(),
		name: ipns.slice(-7),
		isDeleted: false,
		lastPull: null,
		autopull: false,
		...subFields,
	}
	if (subFields.encryptedBy && subFields.derivationJWKstring) {
		let privateKey: CryptoKey, remotePubDerivationKey: CryptoKey
		try {
			privateKey = (yield agent.getDerivationKeypair()).privateKey
		} catch (error) {
			throw new Error('failed to get private key')
		}
		try {
			remotePubDerivationKey = yield importPublicDerivationKey(subFields.derivationJWKstring)
			subscriptionObj.encryptedWith = yield deriveSharedEncryptionKey(privateKey, remotePubDerivationKey, true)

			// doTestEncryptDecrypt(subscriptionObj) // ! This derived key works in the test... but not with the payloads from ipfs
		} catch (error) {
			throw new Error('failed to importPublicDerivationKey')
		}
		subscriptionObj.publishedBy = subFields.encryptedBy
		subscriptionObj.encryptedFor = ag
		subscriptionObj.name = `${subFields.encryptedBy} => ${ag}`
	}
	if (agent.subscriptions.find(s => s.id === subscriptionObj.id)) {
		yield agent.updateSub(subscriptionObj.id, subscriptionObj as ISubscription) // update the subscription
	} else {
		yield agent.addSub(subscriptionObj as ISubscription) // insert the subscription
	}
	DEBUG('[upsertSubscription]', subscriptionObj)

	return subscriptionObj
})

export const addPublication = flow(function* addPublication(
	pubFields: Partial<Pick<IPublication, 'name' | 'encryptedFor' | 'selectors'>> & { derivationJWKstring?: string } = {},
) {
	const agent = useAgent()
	const { ag: publishedBy, getDerivationKeypair } = agent
	const { privateKey, publicKey } = (yield getDerivationKeypair()) as CryptoKeyPair

	const counterFromLocalStorage = localStorage.getItem('agent.pubCounter')
	const pubCounter = counterFromLocalStorage ? +counterFromLocalStorage + 1 : 1
	localStorage.setItem('agent.pubCounter', pubCounter.toFixed(0))
	LOG('Adding publication:', { publishedBy, pubFields })
	const generateEd25519KeyPairFromSeed = keys.supportedKeys.ed25519.generateKeyPairFromSeed
	DEBUG('creating new pubkey derivation via mnemkey')
	const newPKseed: Uint8Array = yield derivePublicationKeySeed({ privateKey, publicKey }, pubCounter)
	VERBOSE({ newPKseed })
	const newPriEd25519 = yield generateEd25519KeyPairFromSeed(newPKseed)
	VERBOSE({ newPriEd25519 })

	// DEBUG('creating new WritableName')
	const newName: W3Name.WritableName = new WritableName(newPriEd25519) // yield W3Name.create() // WritableName.
	DEBUG('new pubkey:', { newPKseed, newPriEd25519, newName })

	const { name = `pub ${newName.toString().slice(-7)}`, encryptedFor, derivationJWKstring } = pubFields
	const pk = newName.key.bytes
	const newPub: IPublication = {
		id: newName.toString(),
		name,
		pk,
		publishedBy,
		pubCounter,
		createdAt: dateNowIso(),
		lastPush: null,
		autopush: false,
	}
	if (encryptedFor && derivationJWKstring) {
		newPub.name = `${newPub.name}=>${encryptedFor}`
		newPub.encryptedFor = encryptedFor
		const remotePubDerivationKey = yield importPublicDerivationKey(derivationJWKstring)

		newPub.encryptedWith = yield deriveSharedEncryptionKey(privateKey, remotePubDerivationKey, true)
	}

	DEBUG('[addPublication]', { newPub, newName })
	yield agent.addPub(newPub)
	return newPub
})

export const updatePubEncryption = flow(async function* updatePubEncryption(
	pubFields: Partial<Pick<IPublication, 'id' | 'encryptedFor' | 'sharedAgents' | 'selectors'>> & { derivationJWKstring?: string } = {},
) {
	LOG('Updating publication encryption:', pubFields)
	const newName: W3Name.WritableName = yield W3Name.create() // ? new ipns name when updating encryption? // TODO deterministic
	const agent = useAgent()
	const { ag: publishedBy, getKnownAgents, crypto } = agent
	const { privateKey } = yield agent.getDerivationKeypair()
	const { id, encryptedFor, sharedAgents } = pubFields
	const pk = newName.key.bytes
	const existingPub = getSubOrPub(pubFields.id) as IPublication
	const pubUpdate: Partial<IPublication> = {}
	const knownAgentsMap = getKnownAgents().get()
	if (sharedAgents) { // multiple agents
		pubUpdate.encryptedFor = null
		pubUpdate.encryptedWith = null
		pubUpdate.sharedAgents = sharedAgents
		// const unifiedSharedAESkey = yield createMultiAgentEncryptionKey() // non deterministic
		const unifiedSharedAESkey = yield createAESkeyForPublication(existingPub)
		DEBUG({ unifiedSharedAESkey })
		const exportedKey = yield globalThis.crypto.subtle.exportKey('raw', unifiedSharedAESkey)
		pubUpdate.sharedKey = unifiedSharedAESkey // ? here we could reimport the shared key as not extractable
		// then WebCrypto would hide it (but its futile for reasons:)
		// 1. if its not extractable then we can't export it to reincrypt it for others
		// 2. if thats the case, we might as well not save it in IDB (but only encrypted for ourselves in the applog)
		// TODO solid strategy for key rotation, saving, sharing, managing
		const keyBase64 = arrayBufferToBase64(exportedKey)
		DEBUG('sharedKey', { exportedKey, keyBase64 })
		pubUpdate.sharedKeyMap = new Map()
		for (const eachAgent of sharedAgents) {
			const eachJWK = knownAgentsMap.get(eachAgent).jwkd as string
			if (eachJWK) {
				const eachPubKey = yield importPublicDerivationKey(eachJWK)
				const derivedKeyToEncryptSharedKeyWith = yield deriveSharedEncryptionKey(privateKey, eachPubKey, true)
				const encryptedSharedKey = await crypto?.aes.encrypt(exportedKey, derivedKeyToEncryptSharedKeyWith, SymmAlg.AES_GCM)
				pubUpdate.sharedKeyMap.set(eachAgent, arrayBufferToBase64(encryptedSharedKey))
				DEBUG('midloop', { pubUpdate })
			} else {
				ERROR('unknown JWK - weirdness', { knownAgentsMap, eachAgent })
			}
		}
	} else if (encryptedFor) { // single agent
		WARN('deprecated')
		// pubUpdate.encryptedFor = encryptedFor
		// const derivationJWKstring = knownAgentsMap.get(encryptedFor).jwkd as string
		// if (derivationJWKstring) {
		// 	const remotePubDerivationKey = yield importPublicDerivationKey(derivationJWKstring)

		// 	pubUpdate.encryptedWith = yield deriveSharedEncryptionKey(privateKey, remotePubDerivationKey, true)
		// 	if (pubUpdate.encryptedWith) {}
		// } else {
		// }
	} else {
		ERROR('encryptedFor unknown', { encryptedFor, knownAgentsMap })
	}

	yield agent.updatePub(id, pubUpdate)
	const traceObj = encryptedFor ? { encryptedFor } : { sharedAgents }
	LOG('[updatePublication]', { pubUpdate }, traceObj)

	return pubUpdate
})

export async function importPublicDerivationKey(derivationJWKstring: string) {
	const parsedPublicJWK = JSON.parse(derivationJWKstring)
	DEBUG({ derivationJWKstring, parsedPublicJWK })

	return await globalThis.crypto.subtle.importKey(
		'jwk',
		parsedPublicJWK,
		{ name: 'ECDH', namedCurve: 'P-256' },
		true,
		[],
	)
}

export async function createMultiAgentEncryptionKey() {
	const newKey = await globalThis.crypto.subtle.generateKey(
		{
			name: 'AES-GCM',
			length: 256,
		},
		true,
		['encrypt', 'decrypt'],
	)
	DEBUG('created new key', { newKey })
	return newKey
}
