import { Logger } from 'besonders-logger'
import { useAgent } from './agent/AgentState'
const { WARN, LOG, DEBUG, VERBOSE, ERROR } = Logger.setup(Logger.INFO) // eslint-disable-line no-unused-vars
export async function verifyPermission(directoryHandle: FileSystemDirectoryHandle | FileSystemHandle, readWrite = true) {
	const options = {}
	if (readWrite) {
		options.mode = 'readwrite'
	}
	// Check if permission was already granted. If so, return true.
	if ((await directoryHandle.queryPermission(options)) === 'granted') {
		return true
	}
	// Request permission. If the user grants permission, return true.
	if ((await directoryHandle.requestPermission(options)) === 'granted') {
		return true
	}
	// The user didn't grant permission, so return false.
	return false
}
// fileHandle is an instance of FileSystemFileHandle..
export async function writeFile(fileHandle, contents) {
	// Create a FileSystemWritableFileStream to write to.
	const writable = await fileHandle.createWritable()
	// Write the contents of the file to the stream.
	await writable.write(contents)
	// Close the file and write the contents to disk.
	await writable.close()
}
export async function getFile(directoryHandle: FileSystemDirectoryHandle, fileName) {
	DEBUG.force('getting', { fileName, directoryHandle })
	const [dn, fn] = fileName.split('/')
	// Create a FileSystemWritableFileStream to write to.
	directoryHandle = await directoryHandle.getDirectoryHandle(dn)
	const fileHandle = await directoryHandle.getFileHandle(`${fn}.car`)
	// Write the contents of the file to the stream.
	return await fileHandle.getFile()
}
export async function chooseFilesystemFolder() {
	// TODO make persistent
	if (!isFileSystemAPIavailable()) return WARN('isFileSystemAPIavailable? no ')
	const agent = useAgent()
	// Destructure the one-element array.
	const directoryHandle = await window.showDirectoryPicker()
	const perms = await verifyPermission(directoryHandle)
	DEBUG({ directoryHandle, perms })
	// Do something with the file handle.
	const newFileHandle = await directoryHandle.getFileHandle('test.car', { create: true })
	writeFile(newFileHandle, 'test-test-1,2')
	agent.setDirectoryHandle(directoryHandle)
}
export function isFileSystemAPIavailable() {
	return !!window.showDirectoryPicker
}
