import { mapArrayToObj } from '@wovin/utils/types'

export type UtilityNames =
	| 'align'
	| 'animate'
	| 'backdrop'
	| 'bg'
	| 'blend'
	| 'border'
	| 'box'
	| 'container'
	| 'content'
	| 'cursor'
	| 'display'
	| 'divide'
	| 'filter'
	| 'flex'
	| 'font'
	| 'gap'
	| 'gradient'
	| 'grid'
	| 'h'
	| 'icon'
	| 'justify'
	| 'list'
	| 'm'
	| 'opacity'
	| 'order'
	| 'outline'
	| 'overflow'
	| 'p'
	| 'place'
	| 'pos'
	| 'ring'
	| 'select'
	| 'shadow'
	| 'space'
	| 'table'
	| 'text'
	| 'transform'
	| 'transition'
	| 'underline'
	| 'w'
	| 'z'

export type VariantSizes =
	| 'sm'
	| 'md'
	| 'lg'
	| 'xl'
	| 'xxl'

export type VariantScreenModes =
	| 'light'
	| 'dark'

export type VariantNames =
	| 'active'
	| 'after'
	| 'all'
	| 'before'
	| 'child'
	| 'enabled'
	| 'first'
	| 'focus'
	| 'hover'
	| 'last'
	| 'root'
	| VariantSizes
	| VariantScreenModes

export type AttributifyNames<Prefix extends string = ''> =
	| `${Prefix}${UtilityNames}`
	| `${Prefix}${VariantNames}`
	| `${Prefix}${VariantNames}:${UtilityNames}`

export interface AttributifyAttributes extends Partial<Record<AttributifyNames, string>> {}

export const ItemsOptions = [
	'start',
	'center',
	'end',
	'baseline',
	'stretch',
] as const
export const ItemsOptionMap = mapArrayToObj(ItemsOptions)
export const DefaultCenter = 'center' as keyof typeof ItemsOptionMap

export const DefaultFull = 'full' as 'full' | number | VariantSizes
export const DefaultSmall = 'small' as 'small' | 'medium' | 'large'
