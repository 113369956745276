import { A } from '@solidjs/router'
import { Component, Show } from 'solid-js'
import { Iconify } from './mini-components'

export const BackLink: Component<{ text?: string; href?: string; onClick?: (event: MouseEvent) => any }> = function BackLink(props) {
	return (
		<A href={props.href || (props.onClick ? '' : '/')} flex='inline items-center' class='a-unstyled gap-2' onClick={props.onClick}>
			<Iconify name='arrow-left-bold' />
			<Show when={props.text}>
				<span>{props.text || 'Back to home'}</span>
			</Show>
		</A>
	)
}
