import { Static } from '@sinclair/typebox'
import { ThreadInMemory } from '@wovin/core'
import { Logger } from 'besonders-logger'
import { ObjectBuilder } from './builder'
import { getMappedVMtoExtend, getUseFx } from './MappedVMbase'
import { TypeMap } from './TypeMap'
import type { VMstatic } from './utils-typemap'
import { knownAtMap } from './utils-typemap'

const { WARN, LOG, DEBUG, VERBOSE, ERROR } = Logger.setup(Logger.INFO) // eslint-disable-line no-unused-vars

const DEF = TypeMap.Provider
export type Provider = Static<typeof DEF>

export interface ProviderVM extends Provider {}
export class ProviderVM extends getMappedVMtoExtend('Provider', DEF) {
	static DEFAULTS = {
		startPage: 'empty-block',
	}
	update(update: Partial<Provider>) {
		this.buildUpdate(update).commit()
	}
}
// export const { 'Wovin/provider': PROVIDER } = knownAtMap
// export const PROVI_ATTRS = Object.values(PROVIDER)
// export const PROVIDER_VMNAME = 'Wovin/provider' as const

// type ProviderT = VMstatic<typeof PROVIDER_VMNAME>
// export interface ProviderVM extends ProviderT /* , ReturnType<typeof getMappedVMtoExtend<typeof PROVIDER_VMNAME>> */ {}
// export class ProviderVM extends getMappedVMtoExtend(PROVIDER_VMNAME) {
// }

// export const ProviderBuilder = ObjectBuilder<ProviderVM, any> // ? deprecate - prefer VM.buildNew() ?
// TODO: runtime/typebox checking is not actually bound here, just TS generics
// export const useProvider = getUseFx(PROVIDER_VMNAME, ProviderVM, ProviderBuilder)
