import { useLocation, useNavigate } from '@solidjs/router'
import { EntityID } from '@wovin/core/applog'
import { Logger } from 'besonders-logger'
import classNames from 'classnames'
import { Component, createMemo, createSignal, For, Show, splitProps } from 'solid-js'
import { phosphorIcons } from '../../unocss.safelist'
import { useAgent } from '../data/agent/AgentState'
import { insertApplogs } from '../data/ApplogDB'
import { copyToClipboard, useBlockContext } from '../data/block-ui-helpers'
import {
	addBlock,
	getAddBlockRelationLogs,
	getRecursiveKidsJSON,
	getRecursiveKidsOPML,
	indentBlk,
	outdentBlk,
	removeBlockRelAndMaybeDelete,
} from '../data/block-utils'
import { useBlk } from '../data/VMs/BlockVM'
import { REL, useRel } from '../data/VMs/RelationVM'
import { useAppSettings } from '../ui/app-settings'
import { useCurrentThread, useRawThread, useReadOnlyState, useRelation } from '../ui/reactive'
import { DivProps, focusViewOnBlock, isTouchDevice, makeNote3Url } from '../ui/utils-ui'
import { FlexBetween, Iconify } from './mini-components'

const { WARN, LOG, DEBUG, VERBOSE, ERROR } = Logger.setup(Logger.DEBUG) // eslint-disable-line no-unused-vars

const [openSignal, setPubChooserOpen] = createSignal(false)
const [onPubSelection, setOnPubSelect] = createSignal((choice) => DEBUG.force('pub choice', choice))

export const PubChooser: Component = (props) => {
	const onShow = (evt) => DEBUG.force('show pub chooser', evt)
	const { publications } = useAgent()
	const onSel = createMemo(() => (evt) => onPubSelection()(evt))
	return (
		<sl-dialog
			onsl-show={onShow}
			label='Choose Publication'
			open={openSignal()}
		>
			<sl-menu
				class='bullet-menu menu-tight'
				onsl-select={onSel()}
			>
				<For each={publications}>
					{({ id, name }) => (
						<sl-menu-item class='-tight -nosub' value={id}>
							<span>{name} - {id.slice(-7)}</span>
						</sl-menu-item>
					)}
				</For>
			</sl-menu>
		</sl-dialog>
	)
}
export const BulletMenu: Component<
	DivProps & {
		blockID: EntityID
		icon: typeof phosphorIcons[number]
		showBlockSettings: () => void
	}
> = (allprops) => {
	const [props, restProps] = splitProps(allprops, ['blockID', 'icon', 'showBlockSettings'])
	const { blockID, showBlockSettings } = props // ! non-reactive
	if (!blockID) return null
	const readOnly = useReadOnlyState()
	const agent = useAgent()
	const location = useLocation()
	const navigate = useNavigate()
	// const { onCopyLink, onCopyContent, onCopyID, onHistory, onFocus, onDelete, onIndent, onOutent, onReply } = handlers

	// const navigate = useNavigate()
	const block = useBlk(blockID)
	const { isReply, kidRelations } = block
	const hasKids = kidRelations.length

	const appSettings = useAppSettings()

	let triggerRef, menuRef, pubChooserRefHolder = { ref: null }
	const handlers = selectionHandlersForBulletMenu(setPubChooserOpen, setOnPubSelect, showBlockSettings)
	const onSelection = (evt) => {
		const itemValue = evt.detail.item.value
		const itemHandlerParams = evt.detail.item.attributes?.['data-handler-param']?.nodeValue
		if (handlers[itemValue]) {
			handlers[itemValue](evt, itemHandlerParams)
		} else {
			WARN('unknown bullet menu item value')
		}
	}
	return (
		<sl-dropdown hoist placement='bottom-start' ref={triggerRef} {...restProps}>
			<Iconify
				slot='trigger'
				size={6}
				scale={isReply ? 70 : 150}
				class={classNames(
					!readOnly() && 'drag-handle cursor-grab',
					isReply && hasKids && 'rotate-180',
				)}
				name={props.icon}
				flex='shrink-0'
				onClick={e => DEBUG(`BULLET CLICK`, e)}
				onAuxClick={e => {
					if (e.button === 1) {
						e.preventDefault()
						focusViewOnBlock({ id: blockID }, location, navigate)
					}
				}}
				onContextMenu={e => {
					if (isTouchDevice()) return
					e.preventDefault()
					e.stopPropagation()
					e.target.parentElement.show()
				}}
			/>
			<sl-menu
				class='bullet-menu menu-tight'
				onsl-select={onSelection}
				ref={menuRef}
			>
				<Show when={!readOnly()}>
					{/* <sl-menu-item> */}
					<FlexBetween>
						{/* //TODO get highligting on roll over better */}
						<sl-menu-item class='-tight' value='outdent'>
							<Iconify class='mr-1' name='text-outdent' scale={120} slot='prefix' />
							<span>{'Outdent'}</span>
						</sl-menu-item>
						<sl-menu-item class='-tight -nosub' value='indent'>
							<span>{'Indent'}</span>
							<Iconify class='ml-1' name='text-indent' scale={120} slot='suffix' />
						</sl-menu-item>
					</FlexBetween>

					<sl-menu-item class='-tight' value='focus'>
						<Iconify name='arrows-in-cardinal-bold' scale={120} slot='prefix' />
						Zoom To
						<div text-xs mt--1 opacity-60>Middle click</div>
					</sl-menu-item>
				</Show>
				<Show when={!readOnly()}>
					<sl-menu-item class='-tight' value='delete'>
						<Iconify name='trash-bold' scale={120} slot='prefix' />
						Delete {/* TODO: "Remove" when the block has other placements */}
					</sl-menu-item>
				</Show>
				<sl-divider />
				{/* <sl-menu-item class="-tight" value={onCopyLink}>Copy note3:// link</sl-menu-item> */}
				<sl-menu-item class='-tight' value='copyID'>Copy ID</sl-menu-item>
				<sl-menu-item class='-tight' value='copyLink'>Copy Link</sl-menu-item>
				<sl-menu-item class='-tight' value='copyShareLink'>Copy Share Link</sl-menu-item>
				<sl-menu-item class='-tight'>
					Copy Content
					<sl-menu slot='submenu'>
						<sl-menu-item class='-tight' value='copyContent' data-handler-param='html'>
							Copy html ul tag
						</sl-menu-item>
						<sl-menu-item class='-tight' value='copyContent' data-handler-param='md'>
							Copy md (for pasting into logseq, zettlr etc)
						</sl-menu-item>
						<sl-menu-item class='-tight' value='copyContent' data-handler-param='opml'>
							Copy OPML (for pasting into WF, note3, etc)
						</sl-menu-item>
					</sl-menu>
				</sl-menu-item>
				<sl-divider />
				<Show when={!readOnly()}>
					<Show when={block.type !== 'smartlist'}>
						<sl-menu-item class='-tight' value='toggleSmartlist'>
							<Iconify name='list-magnifying-glass' scale={120} slot='prefix' />
							Create smartlist
						</sl-menu-item>
					</Show>
					<sl-menu-item class='-tight' value='reply'>
						<Iconify name='arrow-bend-down-right-bold' slot='prefix' />
						Reply
					</sl-menu-item>
					<Show when={appSettings.homeBlock}>
						<sl-menu-item class='-tight' value='addToHome'>
							<Iconify class='mr-1' name='list-plus' scale={120} slot='prefix' />
							<span>{'Add To Home'}</span>
						</sl-menu-item>
					</Show>
				</Show>
				<sl-menu-item class='-tight' value='settings'>
					<Iconify class='mr-1' name='gear-bold' scale={120} />
					Block Settings
				</sl-menu-item>
				{
					/* <sl-menu-item class='-tight' value='history'>
					<Iconify name='clock-counter-clockwise-bold' scale={85} />
					TODO History Asof
				</sl-menu-item> */
				}
			</sl-menu>
		</sl-dropdown>
	)
}

const selectionHandlersForBulletMenu = (setPubChooserOpen, setOnPubSelect, showBlockSettings) => {
	const rawThread = useRawThread()
	const currentThread = useCurrentThread()

	const blockContext = useBlockContext()
	const relation = blockContext.relationToParent && useRelation(blockContext.relationToParent)
	const { parentContext } = blockContext

	const grandParentRelID = parentContext?.relationToParent ?? null
	// const grandParentVM = grandParentID ? BlockVM.get(grandParentID, rawDS) : null
	const grandParentRelVM = useRel(grandParentRelID, currentThread)
	const grandParentID = grandParentRelVM?.childOf

	const blockID = blockContext.id
	const location = useLocation()
	const navigator = useNavigate()
	const appSettings = useAppSettings()
	const onCopyLink = (evt: MouseEvent) => {
		DEBUG.force('copyLink', evt)
		const pub = useAgent().w3NamePublic // TODO: smart-select pub & UX for other cases
		// const n3url = `note3://publication/${pub}/?focus=${blockID}`
		const n3url = makeNote3Url({ pub, block: blockID })
		DEBUG('Note3 url', n3url)
		copyToClipboard(n3url)
	}

	const onReply = (evt: MouseEvent) => {
		const blkVM = useBlk(blockID, currentThread)

		const quotedContent = blkVM.selectionText ? `>${blkVM.selectionText}` : ''
		const added = addBlock({ thread: useCurrentThread(), asChildOf: blockID, content: quotedContent })
		const newBlkVM = useBlk(added, currentThread)
		VERBOSE('new reply block', added, { blkVM, newBlkVM, selection: blkVM.selectionText })
		const currentRelation = newBlkVM.currentRelation
		const replyApplog = {
			en: currentRelation.en,
			at: REL.isReply,
			vl: true,
		}
		DEBUG('new block as reply', replyApplog, added, { blkVM, newBlkVM, currentRelation })
		insertApplogs(currentThread, [replyApplog])
	}

	const onCopyShareLink = (evt: MouseEvent, mode: 'opml' | 'md' | 'html' = 'opml') => {
		DEBUG.force('which pubs is', blockID, 'included in?') // TODO

		const onSelect = (selEvt) => {
			DEBUG.force(blockID, selEvt)
			const pub = selEvt.detail.item.value
			DEBUG.force(blockID, 'selected', pub)
			const n3url = makeNote3Url({ pub, block: blockID })
			DEBUG('Note3 url', n3url)
			copyToClipboard(n3url)
			setPubChooserOpen(false)
		}
		setOnPubSelect(() => onSelect)
		setPubChooserOpen(true)
	}
	const onCopyContent = (evt: MouseEvent, mode: 'opml' | 'md' | 'html' = 'opml') => {
		const formattedContent = mode === 'opml'
			? getRecursiveKidsOPML(blockID)
			: mode === 'md'
			? getRecursiveKidsJSON(blockID, 'md')
			: getRecursiveKidsJSON(blockID, 'html')
		DEBUG.force('Note3 content', blockID, { evt, formattedContent })
		copyToClipboard(formattedContent)
	}

	function toggleSmartlist() {
		const block = useBlk(blockID, currentThread)

		block.buildUpdate({ type: block.type === 'smartlist' ? 'text' : 'smartlist' }).commit(rawThread)
	}

	const settings = () => {
		DEBUG.force('show settings', blockID)
		showBlockSettings()
	}
	const addToHome = () => {
		if (!appSettings.homeBlock) return WARN('no homeBlock set')
		const addRelationLogs = getAddBlockRelationLogs({
			thread: currentThread,
			asChildOf: appSettings.homeBlock,
			blockID,
			focus: true,
			bottom: true,
		})
		DEBUG.force({ addRelationLogs })
		insertApplogs(currentThread, addRelationLogs)
	}
	const returnHandlers = {
		copyID: (_evt: MouseEvent) => copyToClipboard(blockID),
		copyLink: onCopyLink,
		copyShareLink: onCopyShareLink,
		copyContent: onCopyContent,
		reply: onReply,
		history: () => blockContext.setPanel({ type: 'history' }),
		focus: () => focusViewOnBlock({ id: blockID }, location, navigator),
		delete: () => removeBlockRelAndMaybeDelete(currentThread, blockID, relation?.get().en),
		indent: () => indentBlk(currentThread, relation?.get()),
		outdent: () => outdentBlk(currentThread, relation?.get(), grandParentID),
		addToHome,
		settings,
		toggleSmartlist,
	} as const

	return returnHandlers
}
