import { comparer } from '@wovin/core/mobx'
import { createSignal, Signal } from 'solid-js'

import { Logger } from 'besonders-logger'
const { WARN, LOG, DEBUG, VERBOSE, ERROR } = Logger.setup(Logger.INFO) // eslint-disable-line no-unused-vars

const appStartTimeStamp = Date.now()
const p = performance.now()
let precision = 0
// milliseconds since epoch (100nanosecond "precision")
export function utcMsTs(): number {
	const now = new Date()
	const newPrecision = Math.round(performance.now() - p)
	precision = newPrecision === precision ? newPrecision + 1 : newPrecision // ensure 1ms difference - basically
	return appStartTimeStamp + precision + (now.getTimezoneOffset() * 60 * 1000)
}

// FROM: https://stackoverflow.com/a/73123810/1633985
export function useLocalStorage<T>(
	key: string,
	defaultValue: T,
	storage = localStorage,
): Signal<T> {
	const initialValue = storage.getItem(key)
		? JSON.parse(storage.getItem(key)) as T
		: defaultValue

	const [value, setValue] = createSignal<T>(initialValue)

	const setValueAndStore = ((arg) => {
		const v = setValue(arg)
		storage.setItem(key, JSON.stringify(v))
		return v
	}) as typeof setValue

	return [value, setValueAndStore]
}

/**
 * returns `arrB` but re-uses entries from `arrA` if equal
 * For use in <For>, as that checks by identity if re-render is needed
 */
export function arrayReUseItemsIfEqual<T>(arrA: T[], arrB: T[], compareWith = comparer.structural): T[] {
	if (!arrA?.length || !arrB?.length) return arrB

	const newArrB = new Array(arrB.length)
	for (let i = 0; i < arrB.length; i++) {
		const elemB = arrB[i]
		const matchingFromA = arrA.find(elemA => compareWith(elemA, elemB))
		if (matchingFromA) {
			newArrB[i] = matchingFromA
			DEBUG(`[arrayReuseItems] re-using`, { elemB, matchingFromA, arrA, arrB })
		} else newArrB[i] = elemB
	}
	DEBUG(`[arrayReuseItems]`, { arrA, arrB, newArrB })
	return newArrB
}
