import { prefixAt, prefixAttrs } from '@wovin/core/query'
import { mapValues } from 'lodash-es'

// !///////////////////!//
// !   DEPRECATED    ! //
// IN FAVOR OF TYPEBOX //
// !///////////////////!//

export interface EntityDef<T> {
	_attrs: (keyof T)[]
}
type ArrayToUnion<T extends readonly (string | number | symbol)[]> = T[number]

const _ENTITY = {
	isDeleted: 'isDeleted',
} as const
export const ENTITY_DEF = {
	..._ENTITY,
	_attrs: Object.values(_ENTITY),
	_attrsFull: Object.values(_ENTITY),
} as const
export type ENTITY = { [K in ArrayToUnion<typeof ENTITY_DEF._attrs>]: any }

const _BLOCK = {
	content: 'content',
	mirrors: 'mirrors',
	pullUrl: 'pullUrl',
} as const
export const BLOCK_DEF = {
	...mapValues(_BLOCK, (name) => prefixAt('block', name)),
	_attrs: Object.values(_BLOCK),
	_attrsFull: [
		...ENTITY_DEF._attrsFull,
		...prefixAttrs('block', Object.values(_BLOCK)),
	],
} as const
// export type BLOCK = { [K in ArrayToUnion<typeof BLOCK_DEF._attrs>]: any }

export const _REL = {
	after: 'after',
	childOf: 'childOf',
	block: 'block',
	isExpanded: 'isExpanded',
	isReply: 'isReply',
} as const
export const REL_DEF = {
	..._REL,
	...mapValues(_REL, (name) => prefixAt('relation', name)),
	_attrs: Object.values(_REL),
	_attrsFull: [
		...ENTITY_DEF._attrsFull,
		...prefixAttrs('relation', Object.values(_REL)),
	],
} as const
// export type REL = { [K in ArrayToUnion<typeof REL_DEF._attrs>]: any }
