import {
	createDebugName,
	EntityID,
	isEncryptedApplog,
	joinThreads,
	lastWriteWins,
	MappedThread,
	observableArrayMap,
	query,
	queryAndMap,
	removeDuplicateAppLogs,
	rollingFilter,
	Thread,
	ThreadOnlyCurrentNoDeleted,
	withoutDeleted,
} from '@wovin/core'
import type { IObservableArray } from '@wovin/core/mobx'
import { autorun, comparer, toJS } from '@wovin/core/mobx'
import { Logger } from 'besonders-logger'
import escapeStringRegexp from 'escape-string-regexp'
import stringify from 'safe-stable-stringify'
// import { baseExtensions } from '../components/TipTapExtentions'
// import { baseExtensions } from
import { BLOCK_DEF, REL_DEF } from './data-types'
import { TiptapContent } from './VMs/TypeMap'

const { WARN, LOG, DEBUG, VERBOSE, ERROR } = Logger.setup(Logger.INFO) // eslint-disable-line no-unused-vars

export function mapAndRecurseKids<R = void>(
	thread: ThreadOnlyCurrentNoDeleted,
	blockID: EntityID,
	mapKid: (kid: { relID: EntityID; blockID: EntityID }) => R,
	joinResults: (blockResult: R, kidResults: R[]) => R,
) {
	const recurse = function recurseOuter(blockID: EntityID, relID: EntityID, trace: EntityID[]) {
		// Recursion checks
		if (trace.includes(blockID)) {
			ERROR(`Kids loop`, { blockID, _relID: relID, _trace: trace } /*, 'fixIt:', function fixIt() {
				insertApplogsInAppDB({ en: relID, at: ENTITY_DEF.isDeleted, vl: true })
			}*/)
			throw new Error(`[recursiveKids.loop] time travel?`)
		}
		if (trace.length > 42) {
			ERROR(`Depth limit reached`, trace)
			throw new Error(`[recursiveKidsCount.maxDepth] How deep can you go?`) // ? is 42 the answers.length)
		}

		// Process this block
		const result = mapKid({ blockID, relID })

		// Process the children
		const kidsQuery = query(thread, [
			{ en: '?kidRelID', at: 'relation/childOf', vl: blockID },
			{ en: '?kidRelID', at: 'relation/block', vl: '?kidID' },
		])
		const kidResults = observableArrayMap(function obsArrMapperForKidResults() {
			// TODO avoid expensive createDebugName if possible
			return kidsQuery.records.map(function kidsQueryRecordsInnerMapper({ kidID, kidRelID }) {
				return recurse(kidID as EntityID, kidRelID as EntityID, [...trace, blockID])
			})
		}, { name: createDebugName({ caller: `mapAndRecurseKids.kidResults`, thread, args: { blockID } }) })

		return joinResults(result, kidResults)
	}
	return recurse(blockID, null, [])
}

export const TAG_MIN_LENGTH = 2 // HACK: for sanity of query performance // ? but are short queries really not needed?
export const RE_TAG_BODY = new RegExp(`[a-zA-Z0-9_:/-]{${TAG_MIN_LENGTH},}`)
export const RE_HASH_TAG_ONLY = new RegExp(`#(${RE_TAG_BODY.source})`)
export const RE_PLUS_TAG_ONLY = new RegExp(`\\+(${RE_TAG_BODY.source})`)
export const RE_AT_TAG_ONLY = new RegExp(`@(${RE_TAG_BODY.source})`)
export const RE_ANY_TAG_ONLY = new RegExp(`([#+@])(${RE_TAG_BODY.source})`)

// context-aware matching
export const RE_TAG_CONTEXT_PRE = new RegExp(`(^|\\s|\\()`)
export const RE_TAG_CONTEXT_POST = new RegExp(`(\\b|$)`) // (i) \b also matches if the tag continues with e.g. /
export const RE_HASH_TAG_WITHCONTEXT = new RegExp(`${RE_TAG_CONTEXT_PRE.source}#(${RE_TAG_BODY.source})${RE_TAG_CONTEXT_POST.source}`)
export const RE_PLUS_TAG_WITHCONTEXT = new RegExp(`${RE_TAG_CONTEXT_PRE.source}\\+(${RE_TAG_BODY.source})${RE_TAG_CONTEXT_POST.source}`)
export const RE_AT_TAG_WITHCONTEXT = new RegExp(`${RE_TAG_CONTEXT_PRE.source}@(${RE_TAG_BODY.source})${RE_TAG_CONTEXT_POST.source}`)
export const RE_ANY_TAG_WITHCONTEXT = new RegExp(`${RE_TAG_CONTEXT_PRE.source}([#+@])(${RE_TAG_BODY.source})${RE_TAG_CONTEXT_POST.source}`)

export function getRegexForTagInContext(tag: string, flags = ''): RegExp {
	return new RegExp(`${RE_TAG_CONTEXT_PRE.source}(${escapeStringRegexp(tag)})${RE_TAG_CONTEXT_POST.source}`, flags)
}
export function plainContentMatchHashTag(content: string) {
	return plainContentMatchTagGeneric(RE_HASH_TAG_WITHCONTEXT, content)
}
export function plainContentMatchPlusTag(content: string) {
	return plainContentMatchTagGeneric(RE_PLUS_TAG_WITHCONTEXT, content)
}
export function plainContentMatchAtTag(content: string) {
	return plainContentMatchTagGeneric(RE_AT_TAG_WITHCONTEXT, content)
}
export function plainContentMatchAnyTag(content: string) {
	return plainContentMatchTagGeneric(RE_ANY_TAG_WITHCONTEXT, content)
}
export function plainContentMatchTagGeneric(regex: RegExp, content: string) {
	const match = regex.exec(content)
	VERBOSE(`[plainContentMatchTagGeneric]`, match, { regex, content })
	return match && match[2].toLocaleLowerCase()
}
export function plainContentMatchSpecificTag(content: string, tag: string) {
	return plainContentMatchTagGeneric(
		getRegexForTagInContext(tag),
		content,
	) !== null // returns empty string on match
}
export function rawContentMatchTag(regexCheap: RegExp, regex: RegExp, vl) {
	const cheapMatch = regexCheap.exec(vl) // (i) cheap match before parsing tiptap content
	if (!cheapMatch) return null
	const match = regex.exec(contentVlToPlaintext(vl)) // HACK: use materialized view
	if (!match) DEBUG(`HEUR but nope`, vl)
	return match && match[2].toLocaleLowerCase()
}

export function getBlocksWithTags(thread: ThreadOnlyCurrentNoDeleted, tags: readonly string[]) {
	if (!tags.length) throw new Error(`[useBlocksWithTags] Empty tags list`)
	tags.forEach(tag => {
		if (!RE_ANY_TAG_ONLY.exec(tag)) throw new Error(`[useBlocksWithTags] Tag too short: '${tag}'`)
	})
	const blocks = queryAndMap(thread, [{
		en: '?blockID',
		at: 'block/content',
		vl: vl =>
			tags.every(tag => {
				return !!plainContentMatchSpecificTag(contentVlToPlaintext(vl as string), tag)
			}),
	} // TODO: serializable REGEXSearchContext.
	], 'blockID') as IObservableArray<EntityID>
	if (DEBUG.isEnabled) {
		autorun(() => {
			DEBUG(`[useBlocksWithTags] updated`, toJS(blocks))
		})
	}
	return blocks
}

export function blockThreadWithRecursiveKids(thread: Thread, blockID: EntityID): MappedThread {
	const currentThread = withoutDeleted(lastWriteWins(thread)) as ThreadOnlyCurrentNoDeleted // HACK: how to do actual withHistory?

	const mappedKids = mapAndRecurseKids(currentThread, blockID, ({ blockID, relID }) => {
		const blockLogsWithHistory = query(thread, [
			{ en: blockID, at: BLOCK_DEF._attrsFull },
		])
		if (blockLogsWithHistory.isEmpty) {
			WARN(`threadRecurse encountered missing block - skipping`, { blockID, relID })
			return null
		}
		let relLogsWithHistory
		if (relID) {
			relLogsWithHistory = query(thread, [
				{ en: relID, at: REL_DEF._attrsFull },
			])
			if (relLogsWithHistory.isEmpty) {
				throw ERROR(`threadRecurse encountered missing relation (how did we get here?!)`, { blockID, relID })
			}
		}
		if (!relID) return blockLogsWithHistory.threadOfAllTrails
		return joinThreads([
			blockLogsWithHistory.threadOfAllTrails,
			relLogsWithHistory.threadOfAllTrails,
		])
	}, (blockThread, kidThreads) => {
		return joinThreads(observableArrayMap(() => [blockThread, ...kidThreads]))
	})
	DEBUG.force('afterMappingKids', { mappedKids })
	return mappedKids
}

export const TIPTAP_EMPTY = { 'content': [{ 'type': 'paragraph' }], 'type': 'doc' }
export const TIPTAP_EMPTY_SERIALIZED = serializeTiptapToVl(TIPTAP_EMPTY)

export function parseBlockContentValue(value: string) {
	if (value === null) {
		return value
	}
	if (!value.startsWith('{')) {
		// HACK too lazy to do migrations yet
		return plaintextStringToTiptap(value)
	}
	return JSON.parse(value)
}

export function plaintextStringToTiptap(value: string) {
	return {
		'type': 'doc',
		'content': [
			{
				'type': 'paragraph',
				'content': !value.length ? undefined : [
					{
						'type': 'text',
						'text': value,
					},
				],
			},
		],
	}
}

export function compareBlockContent(contentA: TiptapContent, contentB) {
	if (comparer.structural(contentA, contentB)) return true
	if (comparer.structural(contentA, TIPTAP_EMPTY) && !contentB) return true // HACK suppress setting initial content if anyways nothing set yet
	return false
}
export function contentVlToPlaintext(content: string) {
	return tiptapToPlaintext(parseBlockContentValue(content))
}
export function tiptapToPlaintext(tiptap: TiptapContent) {
	return tiptap?.content.map(c => c?.content).flatMap(c => c?.map(c => c?.text)).join('') as string | null
}
export function serializeTiptapToVl(tiptap: TiptapContent) {
	return stringify(tiptap)
}

export function onlyFromAgent(
	thread: Thread,
	ag: string,
) {
	return rollingFilter(thread, { ag })
}

export function sanityCheckLogs(applogs: any) {
	DEBUG.force('sanityCheckLogs', { applogs })
	return removeDuplicateAppLogs(applogs.map(log => {
		if (isEncryptedApplog(log)) {
			VERBOSE('encrypted log', log)
			return log
		}
		return { ...log, pv: log.pv ?? null } // HACK: tolerate old logs without pv
	}))
}
