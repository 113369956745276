import type { EntityID, Thread } from '@wovin/core'
import { Logger } from 'besonders-logger'
import { Component, createMemo, createSignal } from 'solid-js'
import { hideBlockSettings } from '../App'
import { plaintextStringToTiptap, serializeTiptapToVl } from '../data/block-utils-nowin'
import { type BlockVM } from '../data/VMs/BlockVM'
import { ButtonGroup, FlexBetween, IconName } from './mini-components'

const { WARN, LOG, DEBUG, VERBOSE, ERROR } = Logger.setup(Logger.INFO, { prefix: '[BlockSettingsDialog]' })

const buttonData = (icon = 'info-bold' as IconName, click = e => LOG('?', e), restProps = {}) => ({ icon, click, restProps })
export const BlockSettingsRow: Component<{
	blockID: EntityID | ''
	thread: Thread
	blockOrFake: Partial<BlockVM>
}> = (props) => {
	const [isTokenHidden, setTokenHidden] = createSignal(props.blockOrFake.isTokenHidden)
	const [isTabs, setTabs] = createSignal(props.blockOrFake.type === 'tabs') // TODO make this signal obselete and use the block prop
	// const blkVM = useBlk(props.blockID)
	const removeSmartList = e =>
		props.blockOrFake.buildUpdate({
			content: serializeTiptapToVl(plaintextStringToTiptap(props.blockOrFake.contentPlaintext ?? '')), // TIPTAP_EMPTY_SERIALIZED,
			type: 'text',
		}).commit(props.thread)

	const restPropsForTabs = createMemo(() => isTabs() ? { variant: 'primary' } : {})

	const toggleTabsType = e => {
		DEBUG.force('toggle tabs', restPropsForTabs(), isTabs(), props.blockOrFake)
		const newValue = !isTabs()
		setTabs(newValue)
		if (props.blockOrFake.type === 'tabs') {
			props.blockOrFake.type = 'text'
		} else {
			props.blockOrFake.type = 'tabs'
		}
	}
	const leftGroup = [
		buttonData('tabs', toggleTabsType, restPropsForTabs()),
		buttonData('trash-bold', removeSmartList),
		buttonData('gear-bold'),
		buttonData(),
	]
	const restPropsForHidden = createMemo(() => isTokenHidden() ? { variant: 'primary' } : {})
	const tokenVisibleIcon = createMemo(() => isTokenHidden() ? 'eye' : 'eye-slash')

	const toggleShowSmartListTokens = e => {
		DEBUG.force('toggle show tokens', tokenVisibleIcon(), restPropsForHidden(), isTokenHidden(), props.blockOrFake)
		const newValue = !isTokenHidden()
		setTokenHidden(newValue)
		if (props.blockOrFake.type === 'smartlist') {
			props.blockOrFake.isTokenHidden = newValue
		}
	}
	const rightGroup = createMemo(() => {
		return [
			buttonData(tokenVisibleIcon(), toggleShowSmartListTokens, restPropsForHidden()),
		]
	}, [
		buttonData(tokenVisibleIcon(), toggleShowSmartListTokens, restPropsForHidden()),
	], { equals: false })
	return (
		<FlexBetween>
			<ButtonGroup buttonData={leftGroup} />
			<ButtonGroup buttonData={rightGroup()} />
		</FlexBetween>
	)
}
export const BlockSettingsDialog: Component<{
	blockID: EntityID | ''
}> = (props) => {
	// DEBUG.force('render', props.blockID, blockSettingsID())
	// if (!props.blockID) return null

	return (
		<sl-dialog
			open={!!props.blockID}
			onsl-after-hide={() => hideBlockSettings()}
		>
			<h4 slot='label' py-1 px-2 m-0>
				BlockSettings for {`${props.blockID}`}
			</h4>
			BlockSettings
		</sl-dialog>
	)
}
