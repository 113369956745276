// import { useKeyring } from '@w3ui/solid-uploader'
import { untracked } from '@wovin/core/mobx'
import { Logger } from 'besonders-logger'
import { debounce } from 'lodash-es'
// HEAD
import { Component, createSignal, Show, splitProps } from 'solid-js'
//
import { createEffect, on } from 'solid-js'
// 3e1fe6a9079c5e1e5ea35d30d46089f5ccd0e1d0
import { useAgent } from '../../data/agent/AgentState'
import { syncState } from '../../ipfs/sync-service'
import { setSkipUserSetup } from '../../pages/MainPage'
import { DivProps } from '../../ui/utils-ui'
import { CopyableID, Iconify } from '../mini-components'
import { pwaReloadFuncSignal, pwaUnregisterFuncSignal, pwaUpdateFuncSignal } from '../PwaReloadPrompt'
import { AgentEditor } from './AgentEditor'
import { MnemDialog } from './MnemDialog'

const { WARN, LOG, DEBUG, VERBOSE, ERROR } = Logger.setup(Logger.INFO) // eslint-disable-line no-unused-vars

export const AgentSettings: Component<DivProps & { initialSetup?: boolean; onSave?: () => void }> = (allProps) => {
	const [props, restProps] = splitProps(allProps, ['initialSetup', 'onSave'])
	DEBUG('Creating <AgentSettings>') // HACK used to render twice and sideEffects should only happen once if possible
	const agent = useAgent() // ? are there reactivity reasons not to destructure here?

	let mnemDialogRef
	return (
		// <div flex='~ col' gap-4>
		<sl-card class='card-header' style='--border-colly: #ffffff77; --padding: var(--sl-spacing-medium)' {...restProps}>
			<div slot='header'>
				Your user info
				<Show when={!props.initialSetup}>{` (${agent.ag})`}</Show>
			</div>

			<div flex='~ col' gap-4>
				<div>
					<div mb-1 font-bold>Set up your agent string:</div>
					<p opacity-60>
						This handle (which includes a <a href='https://en.wikipedia.org/wiki/Decentralized_identifier' target='_blank'>DID</a>){' '}
						is what other devices will see as the author of the data you create - <em>if you choose to share it</em>.
					</p>
					<AgentEditor
						mb-2
						initialSetup={props.initialSetup}
						onSave={props.onSave}
					/>
				</div>
			</div>

			<div slot='footer' flex='~ wrap justify-start' gap-2>
				<MnemDialog mnemDialogRef={mnemDialogRef} />
			</div>
			{/* TODO: <SlCopyButton  />  */}
		</sl-card>
		// </div>
	)
}
