import { action } from '@wovin/core/mobx'
import { Logger } from 'besonders-logger'

import { Thread, withoutDeleted } from '@wovin/core'
import { Applog, type ApplogForInsertOptionalAgent, hasAg } from '@wovin/core/applog'
import { arrayIfSingle, ArrayOrSingle } from '@wovin/core/types'
import { ThreadIDB } from './datalog/local-applog-idb'
import { initUseAgent } from './lazy-agent'
import { loadAppThread, sanityCheck } from './loadAppThread'

const { WARN, LOG, DEBUG, VERBOSE, ERROR } = Logger.setup(Logger.INFO) // eslint-disable-line no-unused-vars

// let applogDB: DatalogDB
let applogDB: ThreadIDB | null | true
let useAgent: Awaited<ReturnType<typeof initUseAgent>>

export async function initApplogDB() {
	if (applogDB) {
		if (applogDB === true) { // = initializing
			throw ERROR('concurrent attempts to initApplogDB')
		}
		WARN('[initApplogDB] already initialized') // happens on HMR
		return applogDB
	}
	applogDB = true // to trigger the above while we're initializing
	useAgent = await initUseAgent()

	// saturate from IDB
	// @ts-expect-error not sure how to do this right
	applogDB = await loadAppThread() as Omit<ThreadIDB, 'filters'> & { filters: [] }

	if (typeof window != 'undefined') {
		// @ts-expect-error because window
		window.db = applogDB
		// @ts-expect-error because window
		window.appThread = applogDB
		// @ts-expect-error because window
		window.thread = applogDB // it's nice to be able to copy-paste into devtools
		// @ts-expect-error because window
		window.currentThread = withoutDeleted(lastWriteWins(applogDB))
		// @ts-expect-error because window
		window.insertApplogs = insertApplogs
	}
	return applogDB as ThreadIDB
}

export function getApplogDB() {
	if (!applogDB) {
		if (import.meta.hot) {
			window.location.reload() // HACK to work around HMR issue
		}
		throw new Error(`Uninitizalized`)
	}
	if (applogDB === true) {
		throw new Error(`ApplogDB init not done yet`)
	}
	return applogDB
}

export const addAgentToApplog = function addAgentToApplog(log: ApplogForInsertOptionalAgent, ag?: string) {
	ag = ag ?? useAgent?.().ag
	if (!ag) throw ERROR(`missing agentHash`)
	return hasAg(log) ? log : ({ ...log, ag }) /* , vl: log.vl //? was this a brain fart or still relevant */
}
export const addAgentToApplogs = function addAgentToApplogs(applogs: readonly ApplogForInsertOptionalAgent[]) {
	const ag = useAgent?.().ag
	if (!ag) throw ERROR(`missing agentHash`)
	return applogs.map(log => addAgentToApplog(log, ag))
}

export const insertApplogs = action(function insertApplogs(thread: Thread, applogs: ArrayOrSingle<ApplogForInsertOptionalAgent>) {
	return thread.insert(addAgentToApplogs(arrayIfSingle(applogs)))
})
export const insertApplogsInAppDB = action(function insertApplogsInAppDB(applogs: ArrayOrSingle<ApplogForInsertOptionalAgent>) {
	return getApplogDB().insert(addAgentToApplogs(arrayIfSingle(applogs)))
})
export const insertCompleteApplogsInAppDBifMissing = action(
	function insertApplogsInAppDB(applogs: ArrayOrSingle<Applog>) {
		const missingApplogs = arrayIfSingle(applogs).filter(log => !(getApplogDB().hasApplog(log, false)))
		return getApplogDB().insert(sanityCheck(missingApplogs))
	},
)

if (import.meta.hot) {
	import.meta.hot.accept((module) => {
		// You may use the new module instance to decide whether to invalidate.
		// if (cannotHandleUpdate(module)) {
		LOG(`HMR applogDB - invalidated `, import.meta.hot, module)
		import.meta.hot.invalidate()
		// }
	})
	// void initApplogDB()
}
