import { Component, For } from 'solid-js'
// import '@shoelace-style/shoelace/dist/themes/dark.css'
// import '../ui/shoelace'

const HelpContent = {
	'Setup AgentString': `example: theuserhandle.PoDdgsmY@dev.note3.kiwi-140.phone
	main concepts:
	  - before the @ is identifying the individual, 
	  - after is identifying the app and device

	userhandle, browser and device name are all editable, 
	app name and short DID are not editable
	
	Basic Setup:
	1. edit your agent string
	2. click the double check mark to save your agentAtoms into the log
	3. edit the default content and publish your applog (unencrypted) with the push button
	`,
	'Setup Encryption': `After setting up your AgentString, you can create a new publication 
	While creating the new publication you can choose an agent to encrypt for 
	 --> (requires applogs that reveal the public key)
	`,
}

const indentGuideStyle = {
	'--indent-guide-width': '2px',
	'--indent-guide-color': '',
	'white-space-collapse': 'preserve-breaks',
}

export const Help: Component<{ focus?: keyof typeof HelpContent }> = ({ focus }) => {
	return (
		<div flex='~ col' w-full>
			{
				/* <Switch fallback={<p>No root focused</p>}>
				<Match when={root()}> */
			}
			<sl-tree
				class='tree-with-lines'
				style={indentGuideStyle}
				p-2
				b='1 solid white rd-1'
			>
				<For each={Object.entries(HelpContent)}>
					{([eachTitle, eachContent]) => {
						const isFocused = eachTitle === focus
						isFocused && console.log({ focus })
						return (
							<sl-tree-item expanded={true}>
								{eachTitle}
								<sl-tree-item expanded={true}>
									{eachContent}
								</sl-tree-item>
							</sl-tree-item>
						)
					}}
				</For>
			</sl-tree>
		</div>
	)
}
