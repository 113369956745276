import { makePersisted } from '@solid-primitives/storage'
import { useNavigate, useSearchParams } from '@solidjs/router'
import { untracked } from '@wovin/core/mobx'
import { Logger } from 'besonders-logger'
import { Accessor, Component, createSignal, untrack } from 'solid-js'
import { AgentSettings } from '../components/settings/AgentSettings'
import { DEMO_USERNAME, useAgent } from '../data/agent/AgentState'
import { createHomeBlockForAgent } from '../data/block-utils'
import { useAppSettings } from '../ui/app-settings'
import { useRawThread, useThreadFromContext } from '../ui/reactive'
import { DivProps } from '../ui/utils-ui'

const { WARN, LOG, DEBUG, VERBOSE, ERROR } = Logger.setup(Logger.DEBUG) // eslint-disable-line no-unused-vars

export const [skipUserSetup, setSkipUserSetup] = makePersisted(
	createSignal(false),
	{ name: 'note3.skipUserSetup' },
)

export function shouldShowAgentSetup() {
	const { username } = useAgent()
	const result = !skipUserSetup() && username === DEMO_USERNAME
	DEBUG('recalculated memo for showUserSetup', result, { username, skip: skipUserSetup() })
	return result
}

export const AgentSetupPage: Component<DivProps & {}> = (restProps) => {
	const [searchParams] = useSearchParams()
	const navigate = useNavigate()
	LOG(`<AgentSetupPage> `, { searchParams: untrack(() => ({ ...searchParams })) })

	return (
		<AgentSettings
			initialSetup={true}
			onSave={() => {
				setSkipUserSetup(true)
				const settings = useAppSettings()
				if (!settings.homeBlock) {
					createHomeBlockForAgent(settings, useRawThread(), useAgent())
				}

				navigate(searchParams.redirect || '/')
			}}
			{...restProps}
		/>
	)
}
