import { getAtom, getDebugName, getDependencyTree, getObserverTree, Reaction } from '@wovin/core/mobx'
import { enableExternalSource } from 'solid-js'

import { Logger } from 'besonders-logger'
const { WARN, LOG, DEBUG, VERBOSE, ERROR } = Logger.setup(Logger.INFO, { prefix: '[solid-mobx]' }) // eslint-disable-line no-unused-vars

// register MobX as an external source
let id = 0
enableExternalSource((fn, trigger) => {
	const reaction = new Reaction(`solid-js@${++id}`, trigger)
	return {
		track: (x) => {
			let next
			reaction.track(() => {
				// console.debug("MOBX", reaction, trigger)
				return (next = fn(x))
			})
			return next
		},
		dispose: () => reaction.dispose(),
	}
})

// not needed:
// export function trackSolidAsMobxComputed<T>(fn: () => T) {
// 	const value = observable.box<T>(null)
// 	createEffect(fn, (newValue) => {
// 		value.set(newValue)
// 	})
// 	return value
// }

const enableSpy = () => {
	// @ts-expect-error window.
	return window.disableSpy = spy(event => {
		VERBOSE(`[mobx]`, event)
	})
}
// if (VERBOSE.isEnabled) enableSpy()

if (typeof window === 'object') {
	// @ts-expect-error window.
	window.getDependencyTree = getDependencyTree
	// @ts-expect-error window.
	window.getObserverTree = getObserverTree
	// @ts-expect-error window.
	window.getAtom = getAtom
	// @ts-expect-error window.
	window.getDebugName = getDebugName
	// @ts-expect-error window.
	window.enableSpy = enableSpy
}
